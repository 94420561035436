import React, { FunctionComponent } from "react";
import ImageButton, { ImageButtonVariant } from "./image-button";
import "./popup.scss";

interface PopupProps {
  headerText: string;
  onCloseClick(): void;
}

const Popup: FunctionComponent<PopupProps> = (props) => {
  const { headerText, onCloseClick } = props;
  return (
    <>
      <div className="overlay"></div>
      <div className="popup-wrapper">
        <div className="popup-block">
          <div className="popup-header">
            <h2>{headerText}</h2>
            <ImageButton
              variant={ImageButtonVariant.CLOSE}
              onClick={() => onCloseClick()}
            />
          </div>
          <div className="popup-content">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default Popup;
