import React from "react";
import User from "../features/user/user.type";
import Banner from "./banner";
import "./banner-with-profile-image.scss";
import { Mode } from "./banner-with-user-info";
import ProfileImage from "./profile-image";

interface BannerWithProfileImageProps {
  user: User;
  mode: Mode;
}

const BannerWithProfileImage = (props: BannerWithProfileImageProps) => {
  const { mode, user } = props;
  return (
    <div className="banner-with-profile-image-wrapper">
      <Banner mode={mode} user={user} />
      <div className="profile-image-holder">
        <ProfileImage mode={mode} user={user} />
      </div>
    </div>
  );
};

export default BannerWithProfileImage;
