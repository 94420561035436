import UserLogin from "./login.types";
import Error from "../../core/model/Error";
import {
  clearLoginResponse,
  fetchLoginFailure,
  fetchLoginRequest,
  fetchLoginSuccess,
} from "./login.actions";
import axios from "axios";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import LoginInput from "./login.types";
import config from "../../config";
import { addUser } from "../user/user.action";

export const loginUser = (loginInput: LoginInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation login($loginInput:LoginInput!){
        login(loginInput:$loginInput){
            isSuccess,
            message,
            user{
                id,
                username,
                firstName,
                lastName,
                email,
                headline,
                phone1,
                phone2,
                profileImageUrl,
                bannerImageUrl,
                isPublicProfile,
                profilePassword,
                links{
                  id,
                  socialMediaType,
                  name,
                  link,
                  showOnProfile,
                  isDefault
                },
                files{
                  id,
                  name,
                  link,
                  fileType,
                  showOnProfile,
                  isDefault
                }
            }
        }
    }
  `;

  dispatch(fetchLoginRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        loginInput: { ...loginInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.login;
      if (data.isSuccess) {
        dispatch(fetchLoginSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(fetchLoginFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchLoginFailure(error.message));
    });
};

export const clearResponse = () => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  dispatch(clearLoginResponse());
};
