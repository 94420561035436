import Error from "../../core/model/Error";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import config from "../../config";
import {
  showLoader,
  getUserSuccess,
  getUserFailure,
  clearPreviewResponse,
} from "./preview.action";
import { GetUserInput } from "./preview.type";

export const getUser = (getUserInput: GetUserInput) => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
        mutation getUser($getUserInput:GetUserInput!){
            getUser(getUserInput:$getUserInput){
                isSuccess,
                message,
                user{
                  id,
                  username,
                  firstName,
                  lastName,
                  email,
                  headline,
                  phone1,
                  phone2,
                  address,
                  profileImageUrl,
                  bannerImageUrl,
                  isPublicProfile,
                  profilePassword,
                  links{
                      id,
                      socialMediaType,
                      name,
                      link,
                      showOnProfile,
                      isDefault
                  },
                  files{
                      id,
                      fileType,
                      link,
                      isDefault,
                      name,
                      showOnProfile
                  }
                }
              }
            }
        `;

  dispatch(showLoader());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        getUserInput: { ...getUserInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.getUser;

      if (data.isSuccess) {
        dispatch(getUserSuccess(data.user));
      } else {
        dispatch(getUserFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(getUserFailure(error.message));
    });
};

export const clearResponse = () => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
        mutation getUser($getUserInput:GetUserInput!){
            getUser(getUserInput:$getUserInput){
                isSuccess,
                message,
                user{
                  id,
                  username,
                  firstName,
                  lastName,
                  email,
                  headline,
                  phone1,
                  phone2,
                  address,
                  profileImageUrl,
                  bannerImageUrl,
                  isPublicProfile,
                  profilePassword,
                  links{
                      id,
                      socialMediaType,
                      name,
                      link,
                      showOnProfile,
                      isDefault
                  },
                  files{
                      id,
                      fileType,
                      link,
                      isDefault,
                      name,
                      showOnProfile
                  }
                }
              }
            }
        `;

  dispatch(clearPreviewResponse());
};
