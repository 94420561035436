import React, { FunctionComponent } from "react";
import "./external-page.layout.scss";
import logo from "../images/logo-white.svg";
import Loader from "../components/loader";

interface PageLayoutProps {
  isLoading: boolean;
}

const PageLayout: FunctionComponent<PageLayoutProps> = (props) => {
  return (
    <>
      {props.isLoading && <Loader />}
      <div className="page-layout">
        <div>{props.children}</div>
      </div>
    </>
  );
};

export default PageLayout;
