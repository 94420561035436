import React from "react";
import "./checkbox.scss";

interface CheckboxProps {
  label?: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked) => void;
}

const Checkbox = (props: CheckboxProps) => {
  const { checked, disabled, name, onChange, label } = props;

  return (
    <div className="checkbox-wrapper">
      <label className="container">
        {label && label}
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className="checkmark"></span>
      </label>
    </div>
    // <input
    //   type="checkbox"
    //   checked={checked}
    //   disabled={disabled}
    //   name={name}
    //   onChange={(e) => onChange(e.target.checked)}
    // />
  );
};

export default Checkbox;
