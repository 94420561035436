import "./action-button.scss";
import callIcon from "../images/icons/call.svg";
import scanIcon from "../images/icons/scan.svg";
import mailIcon from "../images/icons/mail.svg";

interface ActionButtonProps {
  variant: ActionButtonVariant;
  mode: ActionButtonMode;
  disabled?: boolean;
  onClick(): void;
}

export enum ActionButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum ActionButtonMode {
  CALL = "call",
  QRCODE = "qrcode",
  MAIL = "mail",
}

const getImage = (mode: ActionButtonMode) => {
  let src;
  switch (mode) {
    case ActionButtonMode.CALL:
      src = callIcon;
      break;
    case ActionButtonMode.QRCODE:
      src = scanIcon;
      break;
    case ActionButtonMode.MAIL:
      src = mailIcon;
      break;
  }

  return src;
};

const getText = (mode: ActionButtonMode) => {
  let text: string;
  switch (mode) {
    case ActionButtonMode.CALL:
      text = "Call";
      break;
    case ActionButtonMode.QRCODE:
      text = "QR Code";
      break;
    case ActionButtonMode.MAIL:
      text = "Email";
      break;
  }

  return text;
};

const ActionButton = (props: ActionButtonProps) => {
  const { mode, disabled, onClick } = props;
  return (
    <div
      key={mode}
      className={`action-button-wrapper  ${disabled && "disabled"}`}
      onClick={() => onClick()}
    >
      <img src={getImage(mode)} alt="" />
      <label>{getText(mode)}</label>
    </div>
  );
};

export default ActionButton;
