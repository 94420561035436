import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  CLEAR_FORGOT_PASSWORD_RESPONSE,
  CLEAR_SET_PASSWORD_RESPONSE,
} from "./forgot-password.action";

export interface ForgotPassword {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
}

const initialState: ForgotPassword = {
  isLoading: false,
  error: "",
  isSuccess: undefined,
};

export const forgotPasswordReducer = (
  state = initialState,
  action: any
): ForgotPassword => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case FORGOT_PASSWORD_FAILURE:
    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    case CLEAR_FORGOT_PASSWORD_RESPONSE:
    case CLEAR_SET_PASSWORD_RESPONSE:
      return { ...state, isLoading: false, isSuccess: undefined, error: "" };
    default:
      return state;
  }
};
