import React, { useState, useEffect, useRef } from "react";
import "./registration.scss";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import Textbox from "../../components/textbox";
import Button, { ButtonVariant } from "../../components/button";
import registrationIcon from "../../images/registration.svg";
import ExternalPageLayout from "../../layouts/external-page.layout";
import PageWrapper, { PageWrapperVariant } from "../../components/page-wrapper";
import ErrorMessage from "../../components/error-message";
import PasswordBox from "../../components/passoword-box";
import { IRegistration } from "./registration.reducer";
import { clearAddDetailsResponse, registerUser } from "./registration.service";
import { routePaths } from "../../infrastructure/constants";
import { encrypt } from "../../core/services/crypto.service";

export const AddUserLink = (props) => {
  const state = useSelector<DefaultRootState, IRegistration>(
    (state) => state.registration
  );

  const dispatch = useDispatch();
  const [userLink, setUserlink] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddDetailsResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addUserLink.isSuccess)
        props.history.push(routePaths.REGISTRATION.VALIDATION);
      else setErrorMessage(state.addUserLink.error);
      // do componentDidUpdate logic
    }
  });

  const onJoinUsClick = (e) => {
    setErrorMessage("");
    if (password !== confirmPassword) {
      setErrorMessage("Password and Confirm Password does not match!");
    } else {
      const encryptedPassword = encrypt(password.trim());
      dispatch(
        registerUser({
          email: state.addEmail.email.trim(),
          username: userLink.trim(),
          password: encryptedPassword,
        })
      );
    }
  };

  return (
    <ExternalPageLayout isLoading={state.addUserLink.isLoading}>
      <PageWrapper
        variant={PageWrapperVariant.PRIMARY}
        icon={registrationIcon}
        title="Join now"
        shortDescription="Choose your user link and password."
      >
        <div className="registration-block">
          <Textbox
            name="userLink"
            placeholderText=""
            label="User link"
            onChange={(e) => setUserlink(e.target.value)}
            prefix="neotaps.com/"
            customClass="userlink-prefix"
            value={userLink}
          />
          <PasswordBox
            name="password"
            placeholderText=""
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <PasswordBox
            name="confirmPassword"
            placeholderText=""
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          <div
            className={`action-block ${errorMessage ? "error-message" : ""} `}
          >
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Button
              text="Join us"
              variant={ButtonVariant.SECONDARY}
              onClick={(e) => onJoinUsClick(e)}
            />
          </div>
        </div>
      </PageWrapper>
    </ExternalPageLayout>
  );
};
