import Action from "../../core/model/action";
import Error from "../../core/model/Error";
import User from "../user/user.type";

export const SHOW_LOADER = "PREVIEW_SHOW_LOADER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const CLEAR_PREVIEW_RESPONSE = "CLEAR_PREVIEW_RESPONSE";

export const showLoader = (): Action<any> => {
  return {
    type: SHOW_LOADER,
  };
};

export const getUserSuccess = (user: User): Action<any> => {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  };
};

export const getUserFailure = (error: Error): Action<Error> => {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
};

export const clearPreviewResponse = (): Action<any> => {
  return {
    type: CLEAR_PREVIEW_RESPONSE,
  };
};
