import { File, Link } from "../user/user.type";

export interface SocialMediaType {
  type: string;
  name: string;
}

export const socialMediaTypes: SocialMediaType[] = [
  { type: "facebook", name: "Facebook" },
  { type: "instagram", name: "Instagram" },
  { type: "linkedin", name: "Linkedin" },
  { type: "snapchat", name: "Snapchat" },
  { type: "tiktok", name: "Tiktok" },
  { type: "spotify", name: "Spotify" },
  { type: "soundcloud", name: "Soundcloud" },
  { type: "youtube", name: "Youtube" },
  { type: "paypal", name: "Paypal" },
  //{type:"applemusic",name:""},
  { type: "twitter", name: "Twitter" },
  { type: "website", name: "Website" },
];

export interface CreateEditLinkInput {
  userId: Number;
  link: Link;
}

export interface DeleteLinkInput {
  userId: Number;
  linkId: Number;
}

export interface CreateEditFileInput {
  userId: Number;
  file: File;
}

export interface DeleteFileInput {
  userId: Number;
  fileId: Number;
}
