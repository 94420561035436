import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../components/back";
import Button, { ButtonVariant } from "../../../components/button";
import Textbox from "../../../components/textbox";
import User, { Link } from "../../user/user.type";
import "./create-edit-link.scss";
import { Home } from "../home.reducer";
import { SocialMediaType } from "../home.type";
import { DefaultRootState } from "../../../core/reducer/root.reducer";
import { createEditLink } from "../home.service";
import ErrorMessage from "../../../components/error-message";

interface CreateEditLinkProps {
  mode: CreateEditMode;
  item: Link;
  onBackClick(): void;
  onSaveCallBack(): void;
}

export enum CreateEditMode {
  CREATE = "Add",
  EDIT = "Edit",
}

const CreateEditLink = (props: CreateEditLinkProps) => {
  const state = useSelector<DefaultRootState, Home>((state) => state.home);
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const dispatch = useDispatch();
  const { mode, onBackClick, onSaveCallBack } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [item, setItem] = useState(props.item);

  useEffect(() => {
    if (state.createEditLink.isSuccess) {
      onSaveCallBack();
    } else setErrorMessage(state.createEditLink.error);
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const onSaveClick = () => {
    dispatch(
      createEditLink({
        userId: parseFloat(user.id.toString()),
        link: {
          ...item,
          id: parseFloat(item.id.toString()),
          link: item.link,
        },
      })
    );
  };

  return (
    <div className="create-edit-link-wrapper">
      <div className="header-block">
        <div className="header-holder">
          <Back onClick={() => onBackClick()} />
          <h2>{`${mode} link`}</h2>
        </div>
      </div>
      <div className="content-wrapper">
        <img
          src={
            require(`../../../images/social-media-icons/${item.socialMediaType}.svg`)
              .default
          }
          alt={item.socialMediaType}
        />
        <span>{item.socialMediaType}</span>

        <Textbox
          name="linkName"
          label="Name your link"
          onChange={(e) => {
            setItem({ ...item, name: e.target.value });
          }}
          value={item.name}
          maxLength={35}
        />

        <Textbox
          name="link"
          label="Enter your link"
          onChange={(e) => {
            setItem({ ...item, link: e.target.value });
          }}
          value={item.link}
          prefix="https://"
        />
        <div className="action-block">
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Button
            variant={ButtonVariant.PRIMARY}
            text="Save"
            onClick={() => onSaveClick()}
            disabled={item.name.length == 0 || item.link.length == 0}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEditLink;
