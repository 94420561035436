/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:c274791a-f5a6-49eb-ad63-e36028ad9c4f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_a7vzQkU77",
    "aws_user_pools_web_client_id": "68bof3b8dpdoh25hp3bgdf9c12",
    "oauth": {},
    "aws_user_files_s3_bucket": "neotaps-user-upload-files111413-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
