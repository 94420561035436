import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../back";
import Button, { ButtonVariant } from "../button";
import ErrorMessage from "../error-message";
import "./add-code.scss";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import User from "../../features/user/user.type";
import {
  addCode,
  clearAddCodeResponse,
} from "../../features/onboarding/onboarding.service";
import { IOnboarding } from "../../features/onboarding/onboarding.reducer";
import SuccessMessage from "../success-message";
import Loader from "../loader";
import Textbox from "../textbox";

interface AddCodeProps {
  onBackClick: () => void;
}
const AddCode = (props: AddCodeProps) => {
  const { onBackClick } = props;
  const dispatch = useDispatch();
  const state = useSelector<DefaultRootState, IOnboarding>(
    (state) => state.onboarding
  );
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddCodeResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addCode.isSuccess) {
        setLoading(false);
        setSuccessMessage("Code added successfully!");
      } else {
        setLoading(false);
        setErrorMessage(state.addCode.error);
      }
      // do componentDidUpdate logic
    }
  });

  const onSaveClick = () => {
    setErrorMessage("");
    setLoading(true);
    dispatch(
      addCode({
        userId: parseFloat(user.id.toString()),
        code: code,
      })
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="add-code-wrapper">
        <div className="header-block">
          <div className="header-holder">
            <Back onClick={() => onBackClick()} />
            <h2>Add code</h2>
          </div>
        </div>
        <div className="add-code-content-block">
          <Textbox
            name="code"
            placeholderText=""
            label="Please enter the code you've received"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <div className={`action-block `}>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {successMessage && <SuccessMessage message={successMessage} />}
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Save"
              onClick={(e) => onSaveClick()}
              disabled={code.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCode;
