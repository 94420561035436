import Action from "../../core/model/action";
import Error from "../../core/model/Error";

export const EDIT_DETAILS_REQUEST = "EDIT_DETAILS_REQUEST";
export const EDIT_DETAILS_SUCCESS = "EDIT_DETAILS_SUCCESS";
export const EDIT_DETAILS_FAILURE = "EDIT_DETAILS_FAILURE";
export const EDIT_DETAILS_CLEAR = "EDIT_DETAILS_CLEAR";

export const editDetailsRequest = (): Action<any> => {
  return {
    type: EDIT_DETAILS_REQUEST,
  };
};

export const edtDetailsSuccess = (): Action<any> => {
  return {
    type: EDIT_DETAILS_SUCCESS,
  };
};

export const editDetailsFailure = (error: Error): Action<Error> => {
  return {
    type: EDIT_DETAILS_FAILURE,
    payload: error,
  };
};

export const clearEditDetails = (): Action<any> => {
  return {
    type: EDIT_DETAILS_CLEAR,
  };
};
