import React, { useState } from "react";
import "./mobile-view.scss";
import BannerWithUserInfo, {
  Mode,
} from "../../components/banner-with-user-info";
import User from "../user/user.type";
import Files from "./files/files";
import Links from "./links/links";

interface MobileViewProps {
  user: User;
  onPreviewClick: () => void;
  onEditClick: () => void;
}

const MobileView = (props: MobileViewProps) => {
  const { user, onPreviewClick, onEditClick } = props;

  enum Menu {
    LINKS = "links",
    FILES = "files",
  }

  const [menu, setMenu] = useState(Menu.LINKS);

  return (
    <div className="home-wrapper-mobile">
      <BannerWithUserInfo
        user={user}
        mode={Mode.HOME}
        onPreviewClick={() => onPreviewClick()}
        onEditClick={() => onEditClick()}
      />

      <div className="home-content">
        <div className="header-links-block">
          <a
            href="#!"
            className={menu === Menu.LINKS ? "selected" : ""}
            onClick={() => setMenu(Menu.LINKS)}
          >
            My Links
          </a>
          <a
            href="#!"
            className={menu === Menu.FILES ? "selected" : ""}
            onClick={() => setMenu(Menu.FILES)}
          >
            My Files
          </a>
        </div>
        <div>
          {menu === Menu.LINKS && <Links links={user.links} />}
          {menu === Menu.FILES && <Files files={user.files} />}
        </div>
      </div>
    </div>
  );
};

export default MobileView;
