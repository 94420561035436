import React, { useState } from "react";
import { useSelector } from "react-redux";
import Back from "../../components/back";
import Banner from "../../components/banner";
import { Mode } from "../../components/banner-with-user-info";
import Button, { ButtonVariant } from "../../components/button";
import ProfileImage from "../../components/profile-image";
import Textbox from "../../components/textbox";
import PageLayout from "../../layouts/page.layout";
import User from "../user/user.type";
import "./browser-view.scss";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { EditDetails as EditDetailsState } from "./edit-details.reducer";

interface BrowserViewProps {
  user: User;
  onBackClick: () => void;
  onSaveClick: (
    firstName,
    lastName,
    email,
    phone1,
    phone2,
    headline,
    address
  ) => void;
}

const BrowserView = (props: BrowserViewProps) => {
  const state = useSelector<DefaultRootState, EditDetailsState>(
    (state) => state.editDetails
  );
  const { user, onBackClick, onSaveClick } = props;
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [headline, setHeadline] = useState(user.headline);
  const [phone1, setPhone1] = useState(user.phone1);
  const [phone2, setPhone2] = useState(user.phone2);
  const [address, setAddress] = useState(user.address);
  return (
    <PageLayout isLoading={state.isLoading}>
      <div className="edit-details-wrapper-browser">
        <div className="edit-details-back-wrapper">
          <Back onClick={() => onBackClick()} />
        </div>
        <div className="edit-details-header-block">
          <h2>Edit details</h2>
        </div>
        <div className="edit-details-content">
          <div className="edit-details-content-block">
            <div className="edit-details-banner">
              <Banner mode={Mode.EDIT} user={user} />
              <div className="edit-details-profile-pic-wrapper">
                <ProfileImage mode={Mode.EDIT} user={user} />
              </div>
            </div>
            <div className="edit-details-form">
              <Textbox
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
              />
              <Textbox
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
              />
              <Textbox
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
              />
              <Textbox
                name="headline"
                value={headline}
                onChange={(e) => setHeadline(e.target.value)}
                label="Occupation / Company"
              />
              <Textbox
                name="phone1"
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
                label="Phone 1"
              />
              <Textbox
                name="phone2"
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
                label="Phone 2"
              />
              <Textbox
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label="Address"
              />
            </div>
            <div className="action-block">
              {/* {errorMessage && <ErrorMessage message={errorMessage} />} */}
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Save"
                onClick={() =>
                  onSaveClick(
                    firstName,
                    lastName,
                    email,
                    phone1,
                    phone2,
                    headline,
                    address
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BrowserView;
