import Error from "../../core/model/Error";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import { SendResetMailInput, SetPasswordInput } from "./forgot-password.type";
import config from "../../config";
import {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  setPasswordRequest,
  setPasswordSuccess,
  setPasswordFailure,
  clearSetPasswordResponse,
  clearForgotPasswordResponse as clearForgotPasswordResponseAction,
} from "./forgot-password.action";

export const clearForgotPasswordResponse = () => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  dispatch(clearForgotPasswordResponseAction());
};

export const sendResetMail = (sendResetMailInput: SendResetMailInput) => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
        mutation sendResetMail($sendResetMailInput:SendResetMailInput!){
            sendResetMail(sendResetMailInput:$sendResetMailInput){
                isSuccess,
                message,
            }
        }
    `;

  dispatch(forgotPasswordRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        sendResetMailInput: { ...sendResetMailInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.sendResetMail;
      if (data.isSuccess) {
        dispatch(forgotPasswordSuccess());
      } else {
        dispatch(forgotPasswordFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(forgotPasswordFailure(error.message));
    });
};

export const resetPassword = (setPasswordInput: SetPasswordInput) => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
        mutation setPassword($setPasswordInput:SetPasswordInput!){
            setPassword(setPasswordInput:$setPasswordInput){
                isSuccess,
                message,
            }
        }
    `;

  dispatch(setPasswordRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        setPasswordInput: { ...setPasswordInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.setPassword;
      if (data.isSuccess) {
        dispatch(setPasswordSuccess());
      } else {
        dispatch(setPasswordFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(setPasswordFailure(error.message));
    });
};

export const clearResetPasswordResponse = () => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  dispatch(clearSetPasswordResponse());
};
