import React, { useEffect, useState, useRef } from "react";
import "./registration.scss";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import Textbox from "../../components/textbox";
import Button, { ButtonVariant } from "../../components/button";
import registrationIcon from "../../images/registration.svg";
import ExternalPageLayout from "../../layouts/external-page.layout";
import PageWrapper, { PageWrapperVariant } from "../../components/page-wrapper";
import ErrorMessage from "../../components/error-message";
import { IRegistration } from "./registration.reducer";
import { checkEmail, clearAddEmailResponse } from "./registration.service";
import { routePaths } from "../../infrastructure/constants";

export const AddEmail = (props) => {
  const state = useSelector<DefaultRootState, IRegistration>(
    (state) => state.registration
  );

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddEmailResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addEmail.isSuccess)
        props.history.push(routePaths.REGISTRATION.ADD_USER_LINK);
      else setErrorMessage(state.addEmail.error);
      // do componentDidUpdate logic
    }
  });

  const onNextClick = (e) => {
    setErrorMessage("");
    dispatch(checkEmail(email.trim()));
  };

  return (
    <ExternalPageLayout isLoading={state.addEmail.isLoading}>
      <PageWrapper
        variant={PageWrapperVariant.PRIMARY}
        icon={registrationIcon}
        title="Join now"
        shortDescription="Please enter the below details to join with us"
      >
        <div className="registration-block">
          <Textbox
            name="email"
            placeholderText=""
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <div
            className={`action-block ${errorMessage ? "error-message" : ""}`}
          >
            {errorMessage && (
              <ErrorMessage message="Email id is already registered" />
            )}
            <Button
              text="Next"
              variant={ButtonVariant.SECONDARY}
              onClick={(e) => onNextClick(e)}
              disabled={email.length === 0}
            />
          </div>
        </div>
      </PageWrapper>
    </ExternalPageLayout>
  );
};
