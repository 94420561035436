import React, { useEffect, useState } from "react";
import "./image-cropper.scss";
import ImageButton, { ImageButtonVariant } from "./image-button";
import Button, { ButtonVariant } from "./button";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

interface ImageCropperProps {
  image: string;
  onCloseClick: () => void;
  onUploadClick: (url) => void;
  minCropBoxWidth?: number;
  minCropBoxHeight?: number;
}

const ImageCropper = (props: ImageCropperProps) => {
  const {
    image,
    onCloseClick,
    onUploadClick,
    minCropBoxWidth,
    minCropBoxHeight,
  } = props;
  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getCroppedImageUrl = () => {
    return new Promise((resolve, reject) => {
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = "profile-image.jpeg";
          const previewUrl = window.URL.createObjectURL(blob);

          //window.URL.revokeObjectURL(previewUrl);
          resolve(previewUrl);
        },
        "image/png",
        1
      );
    });
  };

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      const url = await getCroppedImageUrl();
      onUploadClick(url);
    }
  };

  return (
    <div className="image-cropper-wrapper">
      <div className="image-cropper-header">
        <ImageButton
          variant={ImageButtonVariant.CLOSE}
          onClick={() => onCloseClick()}
        />
        <h2>Position and crop your picture</h2>
      </div>
      <div className="image-cropper-container">
        <Cropper
          style={{ height: "50vh", width: "100%" }}
          initialAspectRatio={1}
          src={image}
          viewMode={1}
          guides={false}
          minCropBoxHeight={minCropBoxHeight ? minCropBoxHeight : 100}
          minCropBoxWidth={minCropBoxWidth ? minCropBoxWidth : 100}
          //cropBoxResizable={false}
          background={false}
          responsive={true}
          //autoCropArea={0}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </div>
      <div className="action-block">
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => getCropData()}
          text="Upload"
        />
      </div>
    </div>
  );
};

export default ImageCropper;
