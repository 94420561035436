import CryptoJS from "crypto-js";
import config from "../../config";

export const encrypt = (textToEncrypt) => {
  return CryptoJS.AES.encrypt(textToEncrypt, config.CRYPTO_KEY).toString();
};

export const decrypt = (textToDecrypt) => {
  var bytes = CryptoJS.AES.decrypt(textToDecrypt, config.CRYPTO_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};
