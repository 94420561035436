import React from "react";
import User from "../user/user.type";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { routePaths } from "../../infrastructure/constants";

import Layout from "../../layouts/layout";
import BrowserView from "./browser-view";
import MobileView from "./mobile-view";
import { editDetails } from "./edit-details.service";

const EditDetails = (props) => {
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const dispatch = useDispatch();

  const onBackClick = () => {
    props.history.push(routePaths.HOME);
  };

  const onSaveClick = (
    firstName,
    lastName,
    email,
    phone1,
    phone2,
    headline,
    address
  ) => {
    dispatch(
      editDetails({
        userId: parseFloat(user.id.toString()),
        firstName,
        lastName,
        email,
        phone1,
        phone2,
        headline,
        address,
        profileImageUrl: user.profileImageUrl ? user.profileImageUrl : "",
        bannerImageUrl: user.bannerImageUrl ? user.bannerImageUrl : "",
      })
    );
  };

  return (
    <Layout
      mobileView={() => (
        <MobileView
          user={user}
          onBackClick={onBackClick}
          onSaveClick={onSaveClick}
        />
      )}
      browserView={() => (
        <BrowserView
          user={user}
          onBackClick={onBackClick}
          onSaveClick={onSaveClick}
        />
      )}
    ></Layout>
  );
};

export default EditDetails;
