import OtpInput from "react-otp-input";
import "./otp-input-box.scss";

interface OtpInputBoxProps {
  value: string;
  onChange: (otp) => void;
}

const OtpInputBox = (props: OtpInputBoxProps) => {
  const { value, onChange } = props;
  return (
    <OtpInput
      value={value}
      onChange={(otp) => onChange(otp)}
      numInputs={4}
      separator={<span className="otp-seperator"></span>}
      inputStyle="otp-input"
      containerStyle="otp-container"
    />
  );
};

export default OtpInputBox;
