import Action from "../../core/model/action";
import Error from "../../core/model/Error";

export const ADD_DETAILS_REQUEST = "ADD_DETAILS_REQUEST";
export const ADD_DETAILS_SUCCESS = "ADD_DETAILS_SUCCESS";
export const ADD_DETAILS_FAILURE = "ADD_DETAILS_FAILURE";
export const CLEAR_ADD_DETAILS_RESPONSE = "CLEAR_ADD_DETAILS_RESPONSE";

export const ADD_CODE_REQUEST = "ADD_CODE_REQUEST";
export const ADD_CODE_SUCCESS = "ADD_CODE_SUCCESS";
export const ADD_CODE_FAILURE = "ADD_CODE_FAILURE";
export const CLEAR_ADD_CODE_RESPONSE = "CLEAR_ADD_CODE_RESPONSE";

export const clearDetailsResponse = (): Action<any> => {
  return {
    type: CLEAR_ADD_DETAILS_RESPONSE,
  };
};

export const addDetailsRequest = (): Action<any> => {
  return {
    type: ADD_DETAILS_REQUEST,
  };
};

export const addDetailsSuccess = (): Action<any> => {
  return {
    type: ADD_DETAILS_SUCCESS,
  };
};

export const addDetailsFailure = (error: Error): Action<Error> => {
  return {
    type: ADD_DETAILS_FAILURE,
    payload: error,
  };
};

export const clearCodeResponse = (): Action<any> => {
  return {
    type: CLEAR_ADD_CODE_RESPONSE,
  };
};

export const addCodeRequest = (): Action<any> => {
  return {
    type: ADD_CODE_REQUEST,
  };
};

export const addCodeSuccess = (): Action<any> => {
  return {
    type: ADD_CODE_SUCCESS,
  };
};

export const addCodeFailure = (error: Error): Action<Error> => {
  return {
    type: ADD_CODE_FAILURE,
    payload: error,
  };
};
