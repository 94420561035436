import Error from "../../core/model/Error";

import { addUser } from "../user/user.action";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import User, { EditDetailsInput } from "./user.type";
import config from "../../config";

export const updateUser = (editDetailsInput: EditDetailsInput) => (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
        mutation editDetails($editDetailsInput:EditDetailsInput!){
            editDetails(editDetailsInput:$editDetailsInput){
                isSuccess,
                message,
                user{
                id,
                username,
                firstName,
                lastName,
                email,
                headline,
                phone1,
                phone2,
                profileImageUrl,
                bannerImageUrl,
                isPublicProfile,
                profilePassword,
                links{
                    id,
                    socialMediaType,
                    name,
                    link,
                    showOnProfile,
                    isDefault
                },
                files{
                    id,
                    fileType,
                    link,
                    isDefault
                }
            }
        }
    }
`;

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        editDetailsInput: { ...editDetailsInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.editDetails;
      if (data.isSuccess) {
        dispatch(addUser(data.user));
      } else {
        //dispatch(createEditLinkFailure(data.message));
      }
    })
    .catch((error) => {
      //dispatch(createEditLinkFailure(error.message));
    });
};
