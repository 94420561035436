import React, { useEffect, useState } from "react";
import ImageButton, {
  ImageButtonVariant,
} from "../../../components/image-button";
import "./add-file.scss";
import fileUploadIcon from "../../../images/file-upload.svg";
import fileIcon from "../../../images/file.svg";
import Textbox from "../../../components/textbox";
import Button, { ButtonVariant } from "../../../components/button";
import { upload } from "../../../core/services/file-upload.service";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../../core/reducer/root.reducer";
import User from "../../user/user.type";
import { Home } from "../home.reducer";
import PageLayout from "../../../layouts/page.layout";
import { createEditFile, clearCreateEditFileResponse } from "../home.service";

interface AddFileProps {
  onCloseClick(): void;
}

const AddFile = (props: AddFileProps) => {
  const state = useSelector<DefaultRootState, Home>((state) => state.home);
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  let fileInput;
  const { onCloseClick } = props;
  const [isLoading, setLoading] = useState(state.isLoading);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(Object);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.createEditFile.isSuccess) {
      onCloseClick();
      dispatch(clearCreateEditFileResponse());
    } else {
      //set error message pending
    }

    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [state]);

  const onFileInputChange = (e) => {
    setUploadedFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
    setFileUploaded(true);
    setFileName("");
  };

  const renderFileUploadBlock = () => {
    return (
      <div className="file-upload-holder">
        <div
          className="file-upload-block"
          onClick={() => {
            fileInput.click();
          }}
        >
          <img src={fileUploadIcon} alt="Upload" />
          <span>Upload</span>
        </div>
      </div>
    );
  };

  const renderFileUploadedBlock = () => {
    return (
      <div className="file-uploaded-block">
        <div className="uploaded-file-item">
          <img src={fileIcon} alt="File" />
          <span>{uploadedFileName}</span>
        </div>
        <div className="reupload-block" onClick={() => onReuploadClick()}>
          <div className="reupload-button">Re-upload</div>
        </div>
        <div className="file-name-block">
          <Textbox
            name="fileName"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            label="Name your file"
            maxLength={35}
          />
        </div>
        <div className="action-block">
          <Button
            variant={ButtonVariant.TERTIARY}
            disabled={fileName.length === 0}
            text="Save"
            onClick={() => onSaveClick()}
          />
        </div>
      </div>
    );
  };

  const onReuploadClick = () => {
    setFile(Object);
    setFileUploaded(false);
    setUploadedFileName("");
  };

  const onSaveClick = async () => {
    setLoading(true);
    const data = await upload(file, user.username, "files");

    const url = data?.location;
    // setProfileImageUrl(url);

    dispatch(
      createEditFile({
        userId: parseFloat(user.id.toString()),
        file: {
          id: 0,
          fileType: "",
          name: fileName,
          link: url,
        },
      })
    );
  };

  return (
    <PageLayout isLoading={isLoading}>
      <div className="add-file-wrapper">
        <h2>Add a file</h2>

        {!fileUploaded ? renderFileUploadBlock() : renderFileUploadedBlock()}

        <input
          type="file"
          //accept="image/jpg"
          style={{ display: "none" }}
          ref={(input) => {
            fileInput = input;
          }}
          onChange={(e) => onFileInputChange(e)}
        />
        <div className="close-file-block">
          <ImageButton
            variant={ImageButtonVariant.CLOSE}
            onClick={() => onCloseClick()}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default AddFile;
