import { actionRow } from "aws-amplify";
import User from "../user/user.type";
import {
  SHOW_LOADER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CLEAR_PREVIEW_RESPONSE,
} from "./preview.action";

export interface Preview {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
  user: User | undefined;
}

const initialState: Preview = {
  isLoading: false,
  error: "",
  isSuccess: undefined,
  user: undefined,
};

export const previewReducer = (state = initialState, action: any): Preview => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        //isLoading: false,
        isSuccess: true,
        error: "",
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
        user: undefined,
      };
    case CLEAR_PREVIEW_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: "",
        isSuccess: undefined,
        user: undefined,
      };
    default:
      return state;
  }
};
