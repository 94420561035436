import "./links.scss";
import EmptyState, { EmptyStateMode } from "../empty-state";
import User, { Link } from "../../user/user.type";
import React, { useState } from "react";
import AddLink from "./add-link";
import CreateEditLink, { CreateEditMode } from "./create-edit-link";
import AddButton from "../add-button";
import SocialMediaBox from "./social-media-box";
import {
  clearCreateEditLinkResponse,
  createEditLink,
  deleteLink,
} from "../home.service";
import ErrorMessage from "../../../components/error-message";
import { useDispatch, useSelector } from "react-redux";
import { Home } from "../home.reducer";
import { DefaultRootState } from "../../../core/reducer/root.reducer";
import PageLayout from "../../../layouts/page.layout";

interface LinksProps {
  links: Link[] | undefined;
}

const Links = (props: LinksProps) => {
  const state = useSelector<DefaultRootState, Home>((state) => state.home);
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const dispatch = useDispatch();

  let { links } = props;
  let newLink: Link = {
    id: 0,
    socialMediaType: "",
    name: "",
    link: "",
    showOnProfile: true,
    isDefault: false,
  };
  const [showAddLinkBlock, setAddLinkBlock] = useState(false);
  const [showAddButton, setAddButton] = useState(true);
  const [showCreateEdit, setCreateEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(newLink);

  const onLinkClick = (socialMediaType: string): string => {
    setAddLinkBlock(false);

    newLink = {
      ...newLink,
      socialMediaType,
    };

    setSelectedItem(newLink);
    setCreateEdit(true);
    return socialMediaType;
  };

  const onAddLinkCloseClick = () => {
    setAddLinkBlock(false);
    setAddButton(true);
  };

  const onCreateEditBackClick = () => {
    if (selectedItem.id === 0) {
      setAddLinkBlock(true);
    }
    setAddButton(true);
    setCreateEdit(false);
  };

  const onCreateEditSaveCallBack = () => {
    setAddLinkBlock(false);
    setAddButton(true);
    setCreateEdit(false);
    dispatch(clearCreateEditLinkResponse());
  };

  const onAddButtonClick = () => {
    setAddButton(false);
    setAddLinkBlock(true);
  };

  const onEditClick = (item: Link) => {
    setAddButton(false);
    setCreateEdit(true);
    setSelectedItem(item);
  };

  const onShowHideClick = (item: Link) => {
    const newItem: Link = {
      ...item,
      id: parseFloat(item.id.toString()),
      showOnProfile: !item.showOnProfile,
    };
    dispatch(
      createEditLink({
        userId: parseFloat(user.id.toString()),
        link: newItem,
      })
    );
  };

  const onDefaultClick = (item: Link) => {
    const newItem: Link = {
      ...item,
      id: parseFloat(item.id.toString()),
      isDefault: !item.isDefault,
    };
    dispatch(
      createEditLink({
        userId: parseFloat(user.id.toString()),
        link: newItem,
      })
    );
  };

  const onDeleteClick = (item: Link) => {
    dispatch(
      deleteLink({
        userId: parseFloat(user.id.toString()),
        linkId: parseFloat(item.id.toString()),
      })
    );
  };

  const renderLinks = () => {
    if (links) {
      links = links.sort((a, b) => Number(a.id) - Number(b.id));
    }
    return (
      <div className="links-block">
        {showAddButton && <AddButton onClick={() => onAddButtonClick()} />}
        {links?.map((item, index) => {
          return (
            <SocialMediaBox
              key={index}
              item={item}
              onEditClick={() => onEditClick(item)}
              onShowHideClick={() => onShowHideClick(item)}
              onDefaultClick={() => onDefaultClick(item)}
              onDeleteClick={() => onDeleteClick(item)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <PageLayout isLoading={state.isLoading}>
      {showAddLinkBlock && (
        <AddLink
          onCloseClick={() => onAddLinkCloseClick()}
          onItemClick={(socialMediaType: string) =>
            onLinkClick(socialMediaType)
          }
        />
      )}
      {showCreateEdit && (
        <CreateEditLink
          item={selectedItem}
          mode={CreateEditMode.CREATE}
          onBackClick={() => onCreateEditBackClick()}
          onSaveCallBack={() => onCreateEditSaveCallBack()}
        />
      )}
      <div className="links-wrapper">
        {links && links.length > 0 ? (
          renderLinks()
        ) : (
          <EmptyState
            mode={EmptyStateMode.LINK}
            onAddClick={() => setAddLinkBlock(true)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Links;
