import React from "react";
import User from "../features/user/user.type";
import BannerWithProfileImage from "./banner-with-profile-image";
import UserInfo from "./user-info";

interface BannerWithUserInfoProps {
  user: User;
  onPreviewClick?(): void;
  onEditClick?(): void;
  mode: Mode;
}

export enum Mode {
  HOME = "home",
  PREVIEW = "preview",
  EDIT = "edit",
}

const BannerWithUserInfo = (props: BannerWithUserInfoProps) => {
  const { user, onPreviewClick, onEditClick, mode } = props;
  return (
    <>
      <BannerWithProfileImage mode={mode} user={user} />
      <UserInfo
        user={user}
        mode={mode}
        onPreviewClick={() => onPreviewClick && onPreviewClick()}
        onEditClick={() => onEditClick && onEditClick()}
      />
    </>
  );
};

export default BannerWithUserInfo;
