import { FunctionComponent } from "react";
import Loader from "../components/loader";
import "./onboarding.layout.scss";

interface OnboardingLayoutProps {
  isLoading: boolean;
}

const OnboardingLayout: FunctionComponent<OnboardingLayoutProps> = (props) => {
  return (
    <>
      {props.isLoading && <Loader />}
      <div className="onboarding-layout">
        <div className="onboarding-body-wrapper">
          <h2>Welcome to Neotaps</h2>
          <p>
            Your account is ready to go. To serve you better we just need a few
            things to setup your account
          </p>
          <div className="content-wrapper">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default OnboardingLayout;
