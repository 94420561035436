import React, { useState } from "react";
import "./password-box.scss";
import showIcon from "../images/textbox-icons/password-show.svg";
import hideIcon from "../images/textbox-icons/password-hide.svg";

interface PasswordBoxProps {
  name: string;
  label?: string;
  placeholderText?: string;
  value: string;
  onChange: (e) => void;
}

const PasswordBox = (props: PasswordBoxProps) => {
  const [type, setType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(hideIcon);
  const { name, label, placeholderText, value, onChange } = props;

  const toggleVisibility = () => {
    if (type === "password") {
      setType("text");
      setEyeIcon(showIcon);
    } else {
      setType("password");
      setEyeIcon(hideIcon);
    }
  };
  return (
    <div className="password-box-wrapper">
      <label>{label}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholderText}
        value={value}
        onChange={(e) => onChange(e)}
        autoComplete="off"
      />
      <img
        src={eyeIcon}
        alt="Show/Hide"
        onClick={toggleVisibility}
        className={placeholderText ? "with-placeholder" : ""}
      />
    </div>
  );
};

export default PasswordBox;
