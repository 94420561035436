import "./back.scss";
import backIcon from "../images/back.svg";

interface BackProps {
  onClick: (e) => void;
}

const Back = (props: BackProps) => {
  const { onClick } = props;
  return (
    <div className="back-wrapper">
      <img src={backIcon} alt="Back" onClick={(e) => onClick(e)} />
    </div>
  );
};

export default Back;
