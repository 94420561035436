import React from "react";
import ImageButton, {
  ImageButtonVariant,
} from "../../../components/image-button";
import { Link } from "../../user/user.type";
import "./social-media-box.scss";

interface SocialMediaBoxProps {
  item: Link;
  onEditClick(): void;
  onShowHideClick(): void;
  onDefaultClick(): void;
  onDeleteClick(): void;
}

const SocialMediaBox = (props: SocialMediaBoxProps) => {
  const {
    item,
    onEditClick,
    onShowHideClick,
    onDefaultClick,
    onDeleteClick,
  } = props;
  return (
    <div className="social-media-box-wrapper">
      <img
        src={
          require(`../../../images/social-media-icons/${item.socialMediaType}.svg`)
            .default
        }
        alt={item.socialMediaType}
        className="social-media-icon"
      />
      <span>{item.name}</span>
      <div className="action-block">
        <ImageButton
          variant={ImageButtonVariant.EDIT}
          onClick={() => onEditClick()}
          customClass="action-item"
        />
        <ImageButton
          variant={
            item.showOnProfile
              ? ImageButtonVariant.SHOW
              : ImageButtonVariant.HIDE
          }
          onClick={() => onShowHideClick()}
          customClass="action-item"
        />
        <ImageButton
          variant={
            item.isDefault
              ? ImageButtonVariant.BOOKMARK_ACTIVE
              : ImageButtonVariant.BOOKMARK
          }
          onClick={() => onDefaultClick()}
          customClass="action-item"
        />
        <ImageButton
          variant={ImageButtonVariant.DELETE}
          onClick={() => onDeleteClick()}
          customClass="action-item"
        />
      </div>
    </div>
  );
};

export default SocialMediaBox;
