import "./files.scss";
import EmptyState, { EmptyStateMode } from "../empty-state";
import User, { File } from "../../user/user.type";
import React, { useState } from "react";
import AddButton from "../add-button";
import ErrorMessage from "../../../components/error-message";
import { useDispatch, useSelector } from "react-redux";
import { Home } from "../home.reducer";
import { DefaultRootState } from "../../../core/reducer/root.reducer";
import PageLayout from "../../../layouts/page.layout";
import AddFile from "./add-file";
import { createEditFile, deleteFile } from "../home.service";
import FileBox from "./file-box";

interface FilesProps {
  files: File[] | undefined;
}

const Files = (props: FilesProps) => {
  const state = useSelector<DefaultRootState, Home>((state) => state.home);
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const dispatch = useDispatch();

  let { files } = props;
  const [showAddFile, setAddFile] = useState(false);
  const [showAddButton, setAddButton] = useState(true);
  const [showUploadBlock, setUploadBlock] = useState(false);

  const onAddFileCloseClick = () => {
    setAddFile(false);
    setAddButton(true);
  };

  const onAddButtonClick = () => {
    setAddButton(false);
    setAddFile(true);
  };

  const onShowHideClick = (item: File) => {
    const newItem: File = {
      ...item,
      id: parseFloat(item.id.toString()),
      showOnProfile: !item.showOnProfile,
    };
    dispatch(
      createEditFile({
        userId: parseFloat(user.id.toString()),
        file: newItem,
      })
    );
  };

  const onDefaultClick = (item: File) => {
    const newItem: File = {
      ...item,
      id: parseFloat(item.id.toString()),
      isDefault: !item.isDefault,
    };
    dispatch(
      createEditFile({
        userId: parseFloat(user.id.toString()),
        file: newItem,
      })
    );
  };

  const onDeleteClick = (item: File) => {
    dispatch(
      deleteFile({
        userId: parseFloat(user.id.toString()),
        fileId: parseFloat(item.id.toString()),
      })
    );
  };

  const renderFiles = () => {
    if (files) {
      files = files.sort((a, b) => Number(a.id) - Number(b.id));
    }
    return (
      <div className="file-list-block">
        {showAddButton && <AddButton onClick={() => onAddButtonClick()} />}

        {files?.map((item, index) => {
          return (
            <FileBox
              key={index}
              item={item}
              onShowHideClick={() => onShowHideClick(item)}
              onDefaultClick={() => onDefaultClick(item)}
              onDeleteClick={() => onDeleteClick(item)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <PageLayout isLoading={state.isLoading}>
      {showAddFile && <AddFile onCloseClick={() => onAddFileCloseClick()} />}
      <div className="files-wrapper">
        {files && files.length > 0 ? (
          renderFiles()
        ) : (
          <EmptyState
            mode={EmptyStateMode.FILE}
            onAddClick={() => setAddFile(true)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Files;
