import "./App.scss";
import { Shop } from "./features/shop/shop";
import { Home } from "./features/home/home";
import { Login } from "./features/login/login";
import { AddEmail } from "./features/registration/add-email";
import { AddUserLink } from "./features/registration/add-user-link";
import { Validation } from "./features/registration/validation";
import { AddDetails } from "./features/onboarding/add-details";
import { AddCode } from "./features/onboarding/add-code";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { routePaths } from "./infrastructure/constants";
import React from "react";
import PrivateRoute from "./infrastructure/private-route";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Preview from "./features/preview";
import EditDetails from "./features/edit";
import ForgotPassword from "./features/forgot-password/forgot-password";
import { ResetPassword } from "./features/forgot-password/reset-password";

function App() {
  Amplify.configure(awsconfig);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routePaths.STORE} component={Shop} />
        <Route exact path={routePaths.LOGIN} component={Login} />
        <Route
          exact
          path={routePaths.FORGOT_PASSWORD}
          component={ForgotPassword}
        />

        <Route
          exact
          path={routePaths.RESET_PASSWORD}
          component={ResetPassword}
        />
        <Route
          exact
          path={routePaths.REGISTRATION.ADD_EMAIL}
          component={AddEmail}
        />
        <Route
          exact
          path={routePaths.REGISTRATION.ADD_USER_LINK}
          component={AddUserLink}
        />
        <Route
          exact
          path={routePaths.REGISTRATION.VALIDATION}
          component={Validation}
        />

        <PrivateRoute
          path={routePaths.ONBOARDING.ADD_DETAILS}
          component={AddDetails}
        />
        <PrivateRoute
          path={routePaths.ONBOARDING.ADD_CODE}
          component={AddCode}
        />

        <PrivateRoute path={routePaths.HOME} component={Home} />
        <PrivateRoute path={routePaths.EDIT} component={EditDetails} />

        <Route path={routePaths.PREVIEW} component={Preview} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
