import Error from "../../core/model/Error";
import {
  showLoader,
  createEditLinkSuccess,
  createEditLinkFailure,
  createEditFileSuccess,
  createEditFileFailure,
  clearCreateEditLink,
  clearCreateEditFile,
} from "./home.action";
import { addUser } from "../user/user.action";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import config from "../../config";
import {
  CreateEditFileInput,
  CreateEditLinkInput,
  DeleteFileInput,
  DeleteLinkInput,
} from "./home.type";

export const createEditLink = (
  createEditLinkInput: CreateEditLinkInput
) => async (dispatch: Dispatch<Action<string | Error | any>>) => {
  const mutation = `
    mutation createEditLink($createEditLinkInput:CreateEditLinkInput!){
        createEditLink(createEditLinkInput:$createEditLinkInput){
            isSuccess,
            message,
            user{
            id,
            username,
            firstName,
            lastName,
            email,
            headline,
            phone1,
            phone2,
            address,
            profileImageUrl,
            bannerImageUrl,
            isPublicProfile,
            profilePassword,
            links{
                id,
                socialMediaType,
                name,
                link,
                showOnProfile,
                isDefault
            },
            files{
                id,
                name,
                fileType,
                link,
                showOnProfile,
                isDefault
            }
          }
        }
      }
    `;

  dispatch(showLoader());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        createEditLinkInput: { ...createEditLinkInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.createEditLink;
      if (data.isSuccess) {
        dispatch(createEditLinkSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(createEditLinkFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(createEditLinkFailure(error.message));
    });
};

export const deleteLink = (deleteLinkInput: DeleteLinkInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation deleteLink($deleteLinkInput:DeleteLinkInput!){
        deleteLink(deleteLinkInput:$deleteLinkInput){
            isSuccess,
            message,
            user{
            id,
            username,
            firstName,
            lastName,
            email,
            headline,
            phone1,
            phone2,
            address,
            profileImageUrl,
            bannerImageUrl,
            isPublicProfile,
            profilePassword,
            links{
                id,
                socialMediaType,
                name,
                link,
                showOnProfile,
                isDefault
            },
            files{
                id,
                name,
                fileType,
                link,
                showOnProfile,
                isDefault
            }
          }
        }
      }
    `;

  dispatch(showLoader());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        deleteLinkInput: { ...deleteLinkInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.deleteLink;
      if (data.isSuccess) {
        dispatch(createEditLinkSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(createEditLinkFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(createEditLinkFailure(error.message));
    });
};

export const clearCreateEditLinkResponse = () => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  dispatch(clearCreateEditLink());
};

export const createEditFile = (
  createEditFileInput: CreateEditFileInput
) => async (dispatch: Dispatch<Action<string | Error | any>>) => {
  const mutation = `
    mutation createEditFile($createEditFileInput:CreateEditFileInput!){
        createEditFile(createEditFileInput:$createEditFileInput){
            isSuccess,
            message,
            user{
            id,
            username,
            firstName,
            lastName,
            email,
            headline,
            phone1,
            phone2,
            address,
            profileImageUrl,
            bannerImageUrl,
            isPublicProfile,
            profilePassword,
            links{
                id,
                socialMediaType,
                name,
                link,
                showOnProfile,
                isDefault
            },
            files{
                id,
                name,
                fileType,
                link,
                showOnProfile,
                isDefault
            }
          }
        }
      }
    `;

  dispatch(showLoader());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        createEditFileInput: { ...createEditFileInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.createEditFile;
      if (data.isSuccess) {
        dispatch(createEditFileSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(createEditFileFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(createEditFileFailure(error.message));
    });
};

export const deleteFile = (deleteFileInput: DeleteFileInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation deleteFile($deleteFileInput:DeleteFileInput!){
        deleteFile(deleteFileInput:$deleteFileInput){
            isSuccess,
            message,
            user{
            id,
            username,
            firstName,
            lastName,
            email,
            headline,
            phone1,
            phone2,
            address,
            profileImageUrl,
            bannerImageUrl,
            isPublicProfile,
            profilePassword,
            links{
                id,
                socialMediaType,
                name,
                link,
                showOnProfile,
                isDefault
            },
            files{
                id,
                name,
                fileType,
                link,
                showOnProfile,
                isDefault
            }
          }
        }
      }
    `;

  dispatch(showLoader());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        deleteFileInput: { ...deleteFileInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.deleteFile;
      if (data.isSuccess) {
        dispatch(createEditFileSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(createEditFileFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(createEditFileFailure(error.message));
    });
};

export const clearCreateEditFileResponse = () => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  dispatch(clearCreateEditFile());
};
