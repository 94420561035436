import React from 'react';
import './error-message.scss';

interface ErrorMessageProps{
    message: string;
}

const ErrorMessage = (props:ErrorMessageProps) =>{
    return(
        <div className="error-message-wrapper">
            <span>{props.message}</span>
        </div>
    )
}

export default ErrorMessage;