import React from "react";
import User from "../../features/user/user.type";
import ActionButton, {
  ActionButtonMode,
  ActionButtonVariant,
} from "../action-button";
import Button, { ButtonVariant } from "../button";
import ImageButton, { ImageButtonVariant } from "../image-button";
import "./mobile-view.scss";

interface MobileViewProps {
  user: User;
  isPreview: boolean;
  onCallClick: () => void;
  onQRCodeClick: () => void;
  onMailClick: () => void;
  onEditClick: () => void;
  onSaveClick: () => void;
  onPreviewClick: () => void;
}

const MobileView = (props: MobileViewProps) => {
  const {
    user,
    isPreview,
    onCallClick,
    onQRCodeClick,
    onMailClick,
    onEditClick,
    onSaveClick,
    onPreviewClick,
  } = props;
  return (
    <div className="user-info-wrapper-mobile">
      <div className="user-info-block">
        <div></div>
        <div className="details-block">
          <h3>{`${user.firstName} ${user.lastName}`}</h3>
          <p>{user.headline}</p>
          <div className="details-action-block">
            <ActionButton
              variant={ActionButtonVariant.PRIMARY}
              mode={ActionButtonMode.CALL}
              onClick={() => onCallClick()}
            />
            <ActionButton
              variant={ActionButtonVariant.PRIMARY}
              mode={ActionButtonMode.QRCODE}
              disabled={false}
              onClick={() => onQRCodeClick()}
            />

            <ActionButton
              variant={ActionButtonVariant.PRIMARY}
              mode={ActionButtonMode.MAIL}
              onClick={() => onMailClick()}
            />
          </div>
        </div>
        <div className={`buttons-block ${isPreview && "preview"}`}>
          {!isPreview && (
            <ImageButton
              variant={ImageButtonVariant.EDIT_ATCIVE}
              onClick={() => onEditClick && onEditClick()}
            />
          )}

          <Button
            variant={isPreview ? ButtonVariant.PRIMARY : ButtonVariant.TERTIARY}
            text="Save Contact"
            customClass={`save-button ${isPreview && "preview"}`}
            onClick={() => onSaveClick()}
          />
          {!isPreview && (
            <ImageButton
              variant={ImageButtonVariant.PREVIEW}
              onClick={() => onPreviewClick && onPreviewClick()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default MobileView;
