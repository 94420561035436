import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preview as PreviewState } from "./preview.reducer";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import "./preview.scss";
import User, { Link, File } from "../user/user.type";
import { ILogin } from "../login/login.reducer";
import { getUser, clearResponse } from "./preview.service";
import ProfileImage from "../../components/profile-image";
import { Mode } from "../../components/banner-with-user-info";
import PasswordBox from "../../components/passoword-box";
import Loader from "../../components/loader";
import Layout from "../../layouts/layout";
import MobileView from "./mobile-view";
import BrowserView from "./browser-view";
import { routePaths } from "../../infrastructure/constants";

interface PreviewObject {
  user: User | undefined;
  showPasswordBlock: boolean;
}

const Preview = (props) => {
  const username = props.match.params.username;

  const state = useSelector<DefaultRootState, PreviewState>(
    (state) => state.preview
  );

  const loggedinUser = useSelector<DefaultRootState, User>(
    (state) => state.user
  );

  const loginState = useSelector<DefaultRootState, ILogin>(
    (state) => state.login
  );

  const isExternalUser: boolean = !(
    loginState.isSuccess === true && loggedinUser.username === username
  );

  const initialPreviewObj: PreviewObject = {
    user: undefined,
    showPasswordBlock: false,
  };

  const [previewObj, setPreviewObj] = useState<PreviewObject>(
    initialPreviewObj
  );
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const onPasswordChange = (password) => {
    setPassword(password);
    const user = previewObj.user;
    if (password === user?.profilePassword) {
      setPreviewObj({ ...previewObj, showPasswordBlock: false });
      checkForDefaultLink(user);
    }
  };

  const dispatch = useDispatch();
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearResponse());
      if (isExternalUser) {
        setLoading(true);
        dispatch(getUser({ username: username }));
      } else {
        setPreviewObj({
          user: loggedinUser,
          showPasswordBlock: !loggedinUser.isPublicProfile,
        });

        if (loggedinUser.isPublicProfile) {
          checkForDefaultLink(loggedinUser);
        }
      }

      mounted.current = true;
    } else {
      if (isExternalUser) {
        if (state.user) {
          window.history.pushState({}, "", `/${state.user.username}`);

          setPreviewObj({
            user: state.user,
            showPasswordBlock: !state.user.isPublicProfile,
          });

          if (state.user.isPublicProfile) {
            checkForDefaultLink(state.user);
          }

          setLoading(false);
        }
      }
    }
  }, [state]);

  const renderPassword = () => {
    const user: User | undefined = previewObj.user;
    if (user) {
      return (
        <div className="preview-password-wrapper">
          <div className="preview-password-block">
            <ProfileImage mode={Mode.PREVIEW} user={user} />
            <PasswordBox
              name="password"
              value={password}
              onChange={(e) => onPasswordChange(e.target.value)}
              placeholderText="Enter profile key"
            />
          </div>
        </div>
      );
    }
  };

  const checkForDefaultLink = (user: User | undefined) => {
    setLoading(true);

    if (user) {
      const defaultLink: Link | undefined =
        user.links && user.links.find((n) => n.isDefault === true);
      if (defaultLink) {
        window.location.href = "https://" + defaultLink.link;
      } else {
        const defaultFile: File | undefined =
          user.files && user.files.find((n) => n.isDefault === true);
        if (defaultFile) {
          window.location.href = defaultFile.link;
        } else {
          setLoading(false);
        }
      }
    }
  };

  const onBackClick = () => {
    props.history.push(routePaths.HOME);
  };

  if (isLoading) return <Loader />;
  const { user, showPasswordBlock } = previewObj;

  if (user) {
    if (showPasswordBlock) {
      return renderPassword();
    }
    return (
      <Layout
        mobileView={() => (
          <MobileView
            user={user}
            isExternalUser={isExternalUser}
            onBackClick={onBackClick}
          />
        )}
        browserView={() => (
          <BrowserView
            user={user}
            isExternalUser={isExternalUser}
            onBackClick={onBackClick}
          />
        )}
      ></Layout>
    );
  }
  return null;
};

export default Preview;
