import {
  EDIT_DETAILS_REQUEST,
  EDIT_DETAILS_SUCCESS,
  EDIT_DETAILS_FAILURE,
  EDIT_DETAILS_CLEAR,
} from "./edit-details.action";

export interface EditDetails {
  isLoading: boolean;
  error: string;
  isSuccess: boolean;
}

const initialState: EditDetails = {
  isLoading: false,
  error: "",
  isSuccess: false,
};

export const editDetailsReducer = (
  state = initialState,
  action: any
): EditDetails => {
  switch (action.type) {
    case EDIT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case EDIT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    case EDIT_DETAILS_CLEAR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: "",
      };
    default:
      return state;
  }
};
