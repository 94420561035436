import Error from "../../core/model/Error";

import {
  CLEAR_LOGIN_RESPONSE,
  FETCH_LOGIN_FAILURE,
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
} from "./login.actions";

export interface ILogin {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
}

const initialState: ILogin = {
  isLoading: false,
  isSuccess: undefined,
  error: "",
};

export const loginReducer = (state = initialState, action: any): ILogin => {
  switch (action.type) {
    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: "",
      };
    case FETCH_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    case CLEAR_LOGIN_RESPONSE:
      return { ...state, isLoading: false, isSuccess: undefined, error: "" };
    default:
      return state;
  }
};
