import React, { useEffect, useRef, useState } from "react";
import "./registration.scss";
import Button, { ButtonVariant } from "../../components/button";
import ErrorMessage from "../../components/error-message";
import ExternalPageLayout from "../../layouts/external-page.layout";
import PageWrapper, { PageWrapperVariant } from "../../components/page-wrapper";
import OtpInputBox from "../../components/otp-input-box";
import { routePaths } from "../../infrastructure/constants";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { IRegistration } from "./registration.reducer";
import {
  clearEmailValidationResponse,
  validateEmail,
} from "./registration.service";
import User from "../user/user.type";

export const Validation = (props) => {
  const state = useSelector<DefaultRootState, IRegistration>(
    (state) => state.registration
  );
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearEmailValidationResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.validation.isSuccess)
        props.history.push(routePaths.ONBOARDING.ADD_DETAILS);
      else setErrorMessage(state.validation.error);
      // do componentDidUpdate logic
    }
  });

  const onDoneClick = (e) => {
    setErrorMessage("");
    // if (code !== state.validation.code.toString()) {
    //   setErrorMessage("Invalid code");
    // } else {
    //   props.history.push(routePaths.ONBOARDING.ADD_DETAILS);
    // }

    dispatch(
      validateEmail({
        userId: parseFloat(user.id.toString()),
        code: parseInt(code),
      })
    );
  };

  const onBackClick = (e) => {
    props.history.push(routePaths.REGISTRATION.ADD_USER_LINK);
  };

  const onResendCodeClick = (e) => {};

  return (
    <ExternalPageLayout isLoading={state.validation.isLoading}>
      <PageWrapper
        variant={PageWrapperVariant.PRIMARY}
        title="Enter 4 digit verification code sent to your registered mail"
        onBackClick={(e) => onBackClick(e)}
      >
        <div className="registration-validation-block">
          <OtpInputBox value={code} onChange={(otp) => setCode(otp)} />
          <a href="#!" onClick={(e) => onResendCodeClick(e)}>
            Resend code
          </a>
          <div
            className={`action-block ${errorMessage ? "error-message" : ""}`}
          >
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Button
              text="Done"
              variant={ButtonVariant.SECONDARY}
              onClick={(e) => onDoneClick(e)}
              disabled={code.length !== 4}
            />
          </div>
        </div>
      </PageWrapper>
    </ExternalPageLayout>
  );
};
