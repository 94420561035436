import React from "react";
import "./loader.scss";
import neoIcon from "../images/neo-icon.svg";

const Loader = () => {
  return (
    <>
      <div className="loader-wrapper">
        {/* <div className="loader-block"> */}
        <img src={neoIcon} alt="Neo" />
        <div className="loader"></div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Loader;
