import { ILogin, loginReducer } from "../../features/login/login.reducer";
import { combineReducers } from "redux";
import {
  IRegistration,
  registrationReducer,
} from "../../features/registration/registration.reducer";
import { userReducer } from "../../features/user/user.reducer";
import IUser from "../../features/user/user.type";
import {
  IOnboarding,
  onboardingReducer,
} from "../../features/onboarding/onboarding.reducer";
import { Home, homeReducer } from "../../features/home/home.reducer";
import {
  EditDetails,
  editDetailsReducer,
} from "../../features/edit/edit-details.reducer";
import {
  Preview,
  previewReducer,
} from "../../features/preview/preview.reducer";
import {
  ForgotPassword,
  forgotPasswordReducer,
} from "../../features/forgot-password/forgot-password.reducer";
import { USER_LOGOUT } from "../../features/user/user.action";

export interface DefaultRootState {
  login: ILogin;
  registration: IRegistration;
  user: IUser;
  onboarding: IOnboarding;
  home: Home;
  editDetails: EditDetails;
  preview: Preview;
  forgotPassword: ForgotPassword;
}

const appReducer = combineReducers<DefaultRootState>({
  login: loginReducer,
  registration: registrationReducer,
  user: userReducer,
  onboarding: onboardingReducer,
  home: homeReducer,
  editDetails: editDetailsReducer,
  preview: previewReducer,
  forgotPassword: forgotPasswordReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
