import Action from "../../core/model/action";
import Error from "../../core/model/Error";

export const CREATE_EDIT_LINK_SUCCESS = "CREATE_EDIT_LINK_SUCCESS";
export const CREATE_EDIT_LINK_FAILURE = "CREATE_EDIT_LINK_FAILURE";
export const CREATE_EDIT_LINK_CLEAR = "CREATE_EDIT_LINK_CLEAR";

export const CREATE_EDIT_FILE_SUCCESS = "CREATE_EDIT_FILE_SUCCESS";
export const CREATE_EDIT_FILE_FAILURE = "CREATE_EDIT_FILE_FAILURE";
export const CREATE_EDIT_FILE_CLEAR = "CREATE_EDIT_FILE_CLEAR";

export const HOME_SHOW_LOADER = "HOME_SHOW_LOADER";

export const showLoader = (): Action<any> => {
  return {
    type: HOME_SHOW_LOADER,
  };
};

export const createEditLinkSuccess = (): Action<any> => {
  return {
    type: CREATE_EDIT_LINK_SUCCESS,
  };
};

export const createEditLinkFailure = (error: Error): Action<Error> => {
  return {
    type: CREATE_EDIT_LINK_FAILURE,
    payload: error,
  };
};

export const clearCreateEditLink = (): Action<any> => {
  return {
    type: CREATE_EDIT_LINK_CLEAR,
  };
};

export const createEditFileSuccess = (): Action<any> => {
  return {
    type: CREATE_EDIT_FILE_SUCCESS,
  };
};

export const createEditFileFailure = (error: Error): Action<Error> => {
  return {
    type: CREATE_EDIT_FILE_FAILURE,
    payload: error,
  };
};

export const clearCreateEditFile = (): Action<any> => {
  return {
    type: CREATE_EDIT_FILE_CLEAR,
  };
};
