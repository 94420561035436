import React, { useState, useEffect, useRef } from "react";
import "./forgot-password.scss";
import { useSelector, useDispatch } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import {
  clearResetPasswordResponse,
  resetPassword,
} from "./forgot-password.service";
import { ForgotPassword as ForgotPasswordState } from "./forgot-password.reducer";
import PasswordBox from "../../components/passoword-box";
import Button, { ButtonVariant } from "../../components/button";
import registrationIcon from "../../images/registration.svg";
import ExternalPageLayout from "../../layouts/external-page.layout";
import PageWrapper, { PageWrapperVariant } from "../../components/page-wrapper";
import { routePaths } from "../../infrastructure/constants";
import ErrorMessage from "../../components/error-message";
import { encrypt } from "../../core/services/crypto.service";
import SuccessMessage from "../../components/success-message";

export const ResetPassword = (props) => {
  const state = useSelector<DefaultRootState, ForgotPasswordState>(
    (state) => state.forgotPassword
  );
  const dispatch = useDispatch();
  const token = props.match.params.token;

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearResetPasswordResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.isSuccess) setSuccessMessage("Password changed successfully !");
      else setErrorMessage(state.error);
      // do componentDidUpdate logic
    }
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onResetPasswordClick = (e): void => {
    if (password !== confirmPassword) {
      setErrorMessage("Password and Confirm Password does not match!");
    } else {
      const encryptedPassword = encrypt(password);
      dispatch(resetPassword({ token: token, password: encryptedPassword }));
    }
  };

  const onSigninClick = () => {
    props.history.push(routePaths.LOGIN);
  };

  return (
    <ExternalPageLayout isLoading={state.isLoading}>
      <PageWrapper
        variant={PageWrapperVariant.PRIMARY}
        icon={registrationIcon}
        title="Reset Password"
      >
        <div className="reset-password-wrapper">
          <PasswordBox
            name="password"
            placeholderText=""
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <PasswordBox
            name="confirmPassword"
            placeholderText=""
            label="Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          <div className="action-block">
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {successMessage && <SuccessMessage message={successMessage} />}
            <Button
              text="Reset"
              variant={ButtonVariant.SECONDARY}
              onClick={(e) => onResetPasswordClick(e)}
              disabled={password.length === 0 || confirmPassword.length === 0}
            />
          </div>
          <p>Go back to</p>
          <a href="#!" onClick={(e) => onSigninClick()}>
            Sign in
          </a>
        </div>
      </PageWrapper>
    </ExternalPageLayout>
  );
};
