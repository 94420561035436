import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../components/back";
import { routePaths } from "../../infrastructure/constants";
import PageLayout from "../../layouts/page.layout";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { ForgotPassword as ForgotPasswordState } from "./forgot-password.reducer";
import "./forgot-password.scss";
import Textbox from "../../components/textbox";
import ErrorMessage from "../../components/error-message";
import Button, { ButtonVariant } from "../../components/button";
import {
  clearForgotPasswordResponse,
  sendResetMail,
} from "./forgot-password.service";
import mailSentImage from "../../images/mail-sent.png";
import ExternalPageLayout from "../../layouts/external-page.layout";

const ForgotPassword = (props) => {
  const state = useSelector<DefaultRootState, ForgotPasswordState>(
    (state) => state.forgotPassword
  );

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearForgotPasswordResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.isSuccess) setShowSuccessBlock(true);
      else setErrorMessage(state.error);
      // do componentDidUpdate logic
    }
  });

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessBlock, setShowSuccessBlock] = useState(false);

  const onBackClick = () => {
    props.history.push(routePaths.LOGIN);
  };

  const dispatch = useDispatch();

  const onSendClick = () => {
    setErrorMessage("");
    dispatch(sendResetMail({ email: email }));
  };

  const renderForgotPassword = () => {
    return (
      <div className="forgot-password-wrapper">
        <div className="forgot-password-header-block">
          <div className="header-holder">
            <Back onClick={() => onBackClick()} />
            <h2>Forgot Password</h2>
          </div>
        </div>
        <div className="forgot-password-content">
          <div>
            <Textbox
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Enter your registered Email id"
            />
            <div className="action-block">
              {errorMessage && <ErrorMessage message={errorMessage} />}
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Send"
                disabled={email.length === 0}
                onClick={() => onSendClick()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSuccessBlock = () => {
    return (
      <div className="forgot-password-sucess-wrapper">
        <div></div>
        <img src={mailSentImage} alt="" />
        <h2>Check your mail</h2>
        <label>
          We have sent you a link to your email to reset the password.
        </label>
        <div className="action-block">
          <Button
            variant={ButtonVariant.PRIMARY}
            text="Back to Login"
            onClick={() => onBackClick()}
          />
        </div>
      </div>
    );
  };

  return (
    // <PageLayout isLoading={state.isLoading}>
    <ExternalPageLayout isLoading={state.isLoading}>
      {!showSuccessBlock ? renderForgotPassword() : renderSuccessBlock()}
    </ExternalPageLayout>
    // </PageLayout>
  );
};

export default ForgotPassword;
