import React from "react";
import "./qr-code-popup.scss";
import Popup from "./popup";
import QRCode from "qrcode.react";
import config from "../config";

interface QRCodePopupProps {
  username: string;
  onCloseClick(): void;
}

const QRCodePopup = (props: QRCodePopupProps) => {
  const { username, onCloseClick } = props;
  return (
    <Popup headerText="QR Code" onCloseClick={() => onCloseClick()}>
      <div className="qr-code-wrapper">
        <div id="content">
          <QRCode value={`${config.APP_URL}${username}`} />
        </div>
      </div>
    </Popup>
  );
};

export default QRCodePopup;
