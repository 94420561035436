import { FunctionComponent } from "react";
import "./page-wrapper.scss";
import Back from "./back";

interface PageWrapperProps {
  variant: PageWrapperVariant;
  icon?: any;
  title: string;
  shortDescription?: string;
  onBackClick?: (e) => void;
}

export enum PageWrapperVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

const PageWrapper: FunctionComponent<PageWrapperProps> = (props) => {
  const { variant, icon, title, shortDescription, onBackClick } = props;

  return (
    <div className={`page-wrapper ${variant}`}>
      <div className="page-wrapper-body">
        {icon && <img src={icon} alt="" className="icon" />}
        {onBackClick && <Back onClick={(e) => onBackClick(e)} />}
        <h1>{title}</h1>
        {shortDescription && (
          <p className="short-description">{shortDescription}</p>
        )}
        <div className="page-wrapper-content">{props.children}</div>
      </div>
    </div>
  );
};

export default PageWrapper;
