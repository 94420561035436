import React, { useState, useEffect, useRef } from "react";
import "./login.scss";
import { useSelector, useDispatch } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { clearResponse, loginUser } from "./login.service";
import { ILogin } from "./login.reducer";
import Textbox from "../../components/textbox";
import PasswordBox from "../../components/passoword-box";
import Button, { ButtonVariant } from "../../components/button";
import registrationIcon from "../../images/registration.svg";
import ExternalPageLayout from "../../layouts/external-page.layout";
import PageWrapper, { PageWrapperVariant } from "../../components/page-wrapper";
import { routePaths } from "../../infrastructure/constants";
import ErrorMessage from "../../components/error-message";
import { encrypt } from "../../core/services/crypto.service";

export const Login = (props) => {
  const state = useSelector<DefaultRootState, ILogin>((state) => state.login);
  const dispatch = useDispatch();

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.isSuccess) props.history.push(routePaths.HOME);
      else setErrorMessage(state.error);
      // do componentDidUpdate logic
    }
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSigninClick = (e): void => {
    const encryptedPassword = encrypt(password);
    dispatch(loginUser({ email: email, password: encryptedPassword }));
  };

  const onJoinNowClick = (e) => {
    e.preventDefault();
    props.history.push(routePaths.REGISTRATION.ADD_EMAIL);
  };

  const onForgotPasswordClick = () => {
    props.history.push(routePaths.FORGOT_PASSWORD);
  };
  return (
    <ExternalPageLayout isLoading={state.isLoading}>
      <PageWrapper
        variant={PageWrapperVariant.PRIMARY}
        icon={registrationIcon}
        title="Sign in to your account"
      >
        <div className="login-block">
          <Textbox
            name="email"
            placeholderText=""
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <PasswordBox
            name="password"
            placeholderText=""
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div className="forgot-password-block">
            <a href="#!" onClick={() => onForgotPasswordClick()}>
              Forgot password?
            </a>
          </div>
          <div className="action-block">
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Button
              text="Sign in"
              variant={ButtonVariant.SECONDARY}
              onClick={(e) => onSigninClick(e)}
              disabled={email.length == 0 && password.length == 0}
            />
          </div>
          <p>Not a member yet?</p>
          <a href="#!" onClick={(e) => onJoinNowClick(e)}>
            Join now
          </a>
        </div>
      </PageWrapper>
    </ExternalPageLayout>
  );
};
