import React from "react";
import "./success-message.scss";

interface SuccessMessageProps {
  message: string;
}

const SuccessMessage = (props: SuccessMessageProps) => {
  return (
    <div className="success-message-wrapper">
      <span>{props.message}</span>
    </div>
  );
};

export default SuccessMessage;
