import React, { useState } from "react";
import User from "../user/user.type";
import { useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { routePaths } from "../../infrastructure/constants";

import Layout from "../../layouts/layout";
import BrowserView from "./browser-view";
import MobileView from "./mobile-view";

export const Home = (props) => {
  const user = useSelector<DefaultRootState, User>((state) => state.user);

  const onPreviewClick = () => {
    props.history.push("/" + user.username);
  };

  const onEditClick = () => {
    props.history.push(routePaths.EDIT);
  };

  const renderMobile = () => {
    return (
      <MobileView
        user={user}
        onPreviewClick={onPreviewClick}
        onEditClick={onEditClick}
      />
    );
  };
  return (
    <Layout
      mobileView={renderMobile}
      browserView={() => (
        <BrowserView
          user={user}
          onPreviewClick={onPreviewClick}
          onEditClick={() => onEditClick()}
        />
      )}
    ></Layout>
  );
};
