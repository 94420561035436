import React, { useState } from "react";
import config from "../../config";
import { getVCard } from "../../core/services/contact-card.service";
import User from "../../features/user/user.type";
import { Mode } from "../banner-with-user-info";
import BrowserView from "./browser-view";
import MobileView from "./mobile-view";
import { isIOS } from "react-device-detect";
import QRCodePopup from "../qr-code-popup";
import Layout from "../../layouts/layout";

interface UserInfoProps {
  user: User;
}

const UserInfo = (props) => {
  const { user, onPreviewClick, onEditClick, mode } = props;
  const [showQRCode, setQRCode] = useState(false);
  const isPreview = mode === Mode.PREVIEW;

  const onMailClick = () => {
    window.location.href = `mailto:${user.email}`;
  };
  const onCallClick = () => {
    if (user.phone1) {
      window.location.href = `tel:${user.phone1}`;
    }
  };
  const onSaveClick = () => {
    let url = "";
    if (isIOS) {
      url = `${config.S3_BASE_URL}${user.username}/contact/${user.username}.vcf`;
    } else {
      const data = getVCard(user);
      url = window.URL.createObjectURL(new Blob([data]));
    }

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${user.firstName} ${user.lastName} - contact.vcf`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {showQRCode && (
        <QRCodePopup
          username={user.username}
          onCloseClick={() => setQRCode(false)}
        />
      )}

      <Layout
        mobileView={() => (
          <MobileView
            user={user}
            isPreview={isPreview}
            onCallClick={onCallClick}
            onQRCodeClick={() => setQRCode(true)}
            onMailClick={onMailClick}
            onEditClick={onEditClick}
            onSaveClick={onSaveClick}
            onPreviewClick={onPreviewClick}
          />
        )}
        browserView={() => (
          <BrowserView
            user={user}
            isPreview={isPreview}
            onCallClick={onCallClick}
            onQRCodeClick={() => setQRCode(true)}
            onMailClick={onMailClick}
            onEditClick={onEditClick}
            onSaveClick={onSaveClick}
            onPreviewClick={onPreviewClick}
          />
        )}
      />
    </>
  );
};

export default UserInfo;
