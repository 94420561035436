//DEV
// const config = {
//   API_URL: "http://localhost:2000/graphql",
//   CRYPTO_KEY: "*SECRET*CRYPTO*KEY*NEOTAPS*",
//   APP_URL: "https://neotaps.com/",
//   S3_BASE_URL: "https://neotaps-user-uploads.s3.amazonaws.com/",
// };

//PROD
const config = {
  API_URL: "https://api.neotaps.com/graphql",
  CRYPTO_KEY: "*SECRET*CRYPTO*KEY*NEOTAPS*",
  APP_URL: "https://neotaps.com/",
  S3_BASE_URL: "https://neotaps-user-uploads.s3.amazonaws.com/",
};

export default config;
