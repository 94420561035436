import React, { useState } from "react";
import "./browser-view.scss";
import Banner from "../../components/banner";
import { Mode } from "../../components/banner-with-user-info";
import UserInfo from "../../components/user-info";
import User from "../user/user.type";
import ProfileImage from "../../components/profile-image";
import Links from "./links/links";
import Files from "./files/files";

interface BrowserViewProps {
  user: User;
  onPreviewClick: () => void;
  onEditClick: () => void;
}

const BrowserView = (props: BrowserViewProps) => {
  const { user, onEditClick, onPreviewClick } = props;
  enum Menu {
    LINKS = "links",
    FILES = "files",
  }

  const [menu, setMenu] = useState(Menu.LINKS);

  return (
    <div className="home-wrapper-browser">
      <Banner mode={Mode.HOME} user={user} />

      <div className="home-wrapper-content">
        <div className="home-user-info-holder">
          <div className="home-profile-pic-holder">
            <ProfileImage user={user} mode={Mode.EDIT} />
          </div>
          <UserInfo
            mode={Mode.EDIT}
            user={user}
            onEditClick={onEditClick}
            onPreviewClick={onPreviewClick}
          />
        </div>
        <div className="home-content-right-block">
          <div className="header-links-block">
            <a
              href="#!"
              className={menu === Menu.LINKS ? "selected" : ""}
              onClick={() => setMenu(Menu.LINKS)}
            >
              My Links
            </a>
            <a
              href="#!"
              className={menu === Menu.FILES ? "selected" : ""}
              onClick={() => setMenu(Menu.FILES)}
            >
              My Files
            </a>
          </div>
          <div className="home-content-main">
            {menu === Menu.LINKS && <Links links={user.links} />}
            {menu === Menu.FILES && <Files files={user.files} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserView;
