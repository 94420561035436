import React from "react";
import ImageButton, { ImageButtonVariant } from "../../components/image-button";
import { Link } from "../user/user.type";
import "./social-media-box.scss";

interface SocialMediaBoxProps {
  item: Link;
}

const SocialMediaBox = (props: SocialMediaBoxProps) => {
  const { item } = props;
  return (
    <div className={`social-media-box-wrapper-preview ${item.socialMediaType}`}>
      <a
        href={"https://" + item.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={`social-media-block`}>
          <img
            src={
              require(`../../images/social-media-icons/preview/${item.socialMediaType}.svg`)
                .default
            }
            alt={item.socialMediaType}
            className="social-media-image"
          />
          <span>{item.name}</span>
        </div>
      </a>
    </div>
  );
};

export default SocialMediaBox;
