import React from "react";
import "./settings-box.scss";

interface SettingsBoxProps {
  image: any;
  text: string;
  onClick: () => void;
}

const SettingsBox = (props: SettingsBoxProps) => {
  const { image, text, onClick } = props;
  return (
    <div className="settings-box-wrapper" onClick={() => onClick()}>
      <img src={image} alt={text} />
      <span>{text}</span>
    </div>
  );
};

export default SettingsBox;
