import Button, { ButtonVariant } from "../../components/button";
import "./empty-state.scss";
import emptyStateLinks from "../../images/empty-state-links.svg";

interface EmptyStateProps {
  mode: EmptyStateMode;
  onAddClick(): void;
}

export enum EmptyStateMode {
  LINK = "link",
  FILE = "file",
}

const EmptyState = (props: EmptyStateProps) => {
  const { mode, onAddClick } = props;
  const text = `Please add your ${
    mode === EmptyStateMode.LINK ? "social links" : "files"
  }`;
  const buttonText = `Add ${mode === EmptyStateMode.LINK ? "links" : "files"}`;
  return (
    <div className="empty-state-wrapper">
      <img src={emptyStateLinks} alt="" />
      <label>{text}</label>
      <Button
        variant={ButtonVariant.PRIMARY}
        text={buttonText}
        customClass="add-links-files"
        onClick={() => onAddClick()}
      />
    </div>
  );
};

export default EmptyState;
