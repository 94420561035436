import React, { useEffect, useRef, useState } from "react";
import "./onboarding.scss";
import OnboardingLayout from "../../layouts/onboarding.layout";
import defaultProfilePic from "../../images/default-profile-pic.svg";
import Textbox from "../../components/textbox";
import PasswordBox from "../../components/passoword-box";
import Button, { ButtonVariant } from "../../components/button";
import ToggleSwitch from "../../components/toggle-switch";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { IOnboarding } from "./onboarding.reducer";
import { addDetails, clearAddDetailsResponse } from "./onboarding.service";
import User from "../user/user.type";
import { routePaths } from "../../infrastructure/constants";
import ErrorMessage from "../../components/error-message";
import ProfileImage from "../../components/profile-image";
import { Mode } from "../../components/banner-with-user-info";

export const AddDetails = (props) => {
  const state = useSelector<DefaultRootState, IOnboarding>(
    (state) => state.onboarding
  );

  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(state.addDetails.isLoading);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [publicProfile, setPublicProfile] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddDetailsResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addDetails.isSuccess)
        props.history.push(routePaths.ONBOARDING.ADD_CODE);
      else setErrorMessage(state.addDetails.error);
      // do componentDidUpdate logic
    }
  });

  const onNextClick = (e) => {
    setErrorMessage("");
    dispatch(
      addDetails({
        userId: parseFloat(user.id.toString()),
        profileImageUrl: user.profileImageUrl ? user.profileImageUrl : "",
        firstName,
        lastName,
        isPublicProfile: publicProfile,
        profilePassword: password,
      })
    );
  };

  return (
    <OnboardingLayout isLoading={isLoading}>
      <div className="add-details-block">
        <span>Upload profile pic</span>
        <div className="profile-pic-wrapper">
          <ProfileImage mode={Mode.EDIT} user={user} />
        </div>
        <Textbox
          name="firstName"
          placeholderText=""
          label="First name"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
        <Textbox
          name="lastName"
          placeholderText=""
          label="Last name"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
        <div className="public-profile-wrapper">
          <label>Make your profile public</label>
          <ToggleSwitch
            id="publicProfile"
            checked={publicProfile}
            onChange={(checked) => setPublicProfile(checked)}
          />
        </div>
        {!publicProfile && (
          <PasswordBox
            name="password"
            label="Set Profile key"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        )}
        <div className={`action-block ${publicProfile ? "public" : "private"}`}>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Button
            variant={ButtonVariant.PRIMARY}
            text="Next"
            onClick={(e) => onNextClick(e)}
          />
        </div>
        <div className="indicator-wrapper">1/2</div>
      </div>
    </OnboardingLayout>
  );
};
