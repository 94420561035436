import React from "react";
import ImageButton, {
  ImageButtonVariant,
} from "../../../components/image-button";
import { File } from "../../user/user.type";
import "./file-box.scss";

interface FileBoxProps {
  item: File;
  onShowHideClick(): void;
  onDefaultClick(): void;
  onDeleteClick(): void;
}

const FileBox = (props: FileBoxProps) => {
  const { item, onShowHideClick, onDefaultClick, onDeleteClick } = props;
  return (
    <div className="file-box-wrapper">
      <img
        src={require(`../../../images/file.svg`).default}
        alt="File"
        className="file-icon"
      />
      <span>{item.name}</span>
      <div className="action-block">
        <ImageButton
          variant={
            item.showOnProfile
              ? ImageButtonVariant.SHOW
              : ImageButtonVariant.HIDE
          }
          onClick={() => onShowHideClick()}
          customClass="action-item"
        />
        <ImageButton
          variant={
            item.isDefault
              ? ImageButtonVariant.BOOKMARK_ACTIVE
              : ImageButtonVariant.BOOKMARK
          }
          onClick={() => onDefaultClick()}
          customClass="action-item"
        />
        <ImageButton
          variant={ImageButtonVariant.DELETE}
          onClick={() => onDeleteClick()}
          customClass="action-item"
        />
      </div>
    </div>
  );
};

export default FileBox;
