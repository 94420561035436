import { File } from "../user/user.type";
import "./file-box.scss";

interface FileBoxProps {
  item: File;
}

const FileBox = (props: FileBoxProps) => {
  const { item } = props;
  return (
    <div className="file-box-wrapper-preview">
      <a href={item.link} target="blank">
        <div className="file-item-preview">
          <img
            src={require(`../../images/file.svg`).default}
            alt="File"
            className="file-icon"
          />
          <span>{item.name}</span>
        </div>
      </a>
    </div>
  );
};

export default FileBox;
