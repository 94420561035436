import React, { useEffect, useRef, useState } from "react";
import "./onboarding.scss";
import OnboardingLayout from "../../layouts/onboarding.layout";
import Textbox from "../../components/textbox";
import Button, { ButtonVariant } from "../../components/button";
import Checkbox from "../../components/checkbox";
import { routePaths } from "../../infrastructure/constants";
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import { IOnboarding } from "./onboarding.reducer";
import { addCode, clearAddCodeResponse } from "./onboarding.service";
import IUser from "../user/user.type";
import ErrorMessage from "../../components/error-message";

export const AddCode = (props) => {
  const state = useSelector<DefaultRootState, IOnboarding>(
    (state) => state.onboarding
  );
  const user = useSelector<DefaultRootState, IUser>((state) => state.user);
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [dontHaveCode, setDontHaveCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddCodeResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addCode.isSuccess) props.history.push(routePaths.HOME);
      else setErrorMessage(state.addCode.error);
      // do componentDidUpdate logic
    }
  });

  const onFinishClick = (e) => {
    if (dontHaveCode) {
      props.history.push(routePaths.HOME);
    } else {
      setErrorMessage("");
      dispatch(
        addCode({
          userId: parseFloat(user.id.toString()),
          code,
        })
      );
    }
  };

  const isFormValid = (): boolean => {
    return (code.length > 3 && !dontHaveCode) ||
      (code.length === 0 && dontHaveCode)
      ? true
      : false;
  };

  const onDontHaveCodeChange = (checked) => {
    if (checked) {
      setCode("");
    }
    setDontHaveCode(checked);
  };

  return (
    <OnboardingLayout isLoading={state.addCode.isLoading}>
      <div className="add-code-block">
        <Textbox
          name="code"
          placeholderText=""
          label="Please enter the code you've received"
          onChange={(e) => setCode(e.target.value)}
          disabled={dontHaveCode}
          value={code}
        />

        <div className="code-check-block">
          <Checkbox
            label="I don't have a code"
            name="haveCode"
            checked={dontHaveCode}
            onChange={(checked) => onDontHaveCodeChange(checked)}
          />
        </div>
        <div className={`action-block ${errorMessage ? "error-message" : ""}`}>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Button
            variant={ButtonVariant.PRIMARY}
            text="Finish"
            onClick={(e) => onFinishClick(e)}
            disabled={!isFormValid()}
          />
        </div>
        <div className="indicator-wrapper">2/2</div>
      </div>
    </OnboardingLayout>
  );
};
