export const routePaths = {
  STORE: "/",
  LOGIN: "/login",
  REGISTRATION: {
    ADD_EMAIL: "/registration/add-email",
    ADD_USER_LINK: "/registration/add-user-link",
    VALIDATION: "/registration/validation",
  },
  ONBOARDING: {
    ADD_DETAILS: "/onboarding/add-details",
    ADD_CODE: "/onboarding/add-code",
  },
  HOME: "/home",
  EDIT: "/edit-details",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  PREVIEW: "/:username",
};
