import "./image-button.scss";

interface ImageButtonProps {
  variant: ImageButtonVariant;
  onClick: (e) => void;
  customClass?: string;
}

export enum ImageButtonVariant {
  EDIT = "edit",
  EDIT_ATCIVE = "edit-active",
  PREVIEW = "preview",
  CLOSE = "close",
  DELETE = "delete",
  FAV = "fav",
  FAV_ACTIVE = "fav-active",
  SHOW = "eye-show",
  HIDE = "eye-hide",
  BOOKMARK = "bookmark",
  BOOKMARK_ACTIVE = "bookmark-active",
}

const ImageButton = (props: ImageButtonProps) => {
  const { variant, onClick, customClass } = props;

  return (
    <div
      className={`image-buton-wrapper ${customClass ? customClass : ""}`}
      onClick={(e) => onClick(e)}
    >
      <img
        src={require(`../images/icons/${variant}.svg`).default}
        alt={variant}
      />
    </div>
  );
};

export default ImageButton;
