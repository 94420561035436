import "./profile-image.scss";
import cameraIcon from "../images/camera.svg";
import { Mode } from "./banner-with-user-info";
import { useState } from "react";
import { upload } from "../core/services/file-upload.service";
import User from "../features/user/user.type";
import Loader from "./loader";
import { updateUser } from "../features/user/user.service";
import defaultProfilePic from "../images/default-profile-pic.svg";
import { useDispatch } from "react-redux";
import ImageCropper from "./image-cropper";

interface ProfileImageProps {
  mode: Mode;
  user: User;
}

const ProfileImage = (props: ProfileImageProps) => {
  const dispatch = useDispatch();
  const { mode, user } = props;
  let fileInput;
  const canEdit = mode === Mode.EDIT;

  const [isLoading, setLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    user.profileImageUrl && user.profileImageUrl.length > 2
      ? user.profileImageUrl
      : defaultProfilePic
  );

  const [image, setImage] = useState(ArrayBuffer[0]);

  const onChange = async (e) => {
    const uploadedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
  };

  const onCroppedImageUpload = async (croppedImageUrl) => {
    setLoading(true);
    let fileName = `profile-image-${new Date().toISOString()}.jpeg`;

    const blob = await fetch(croppedImageUrl).then((r) => r.blob());
    const file = new File([blob], fileName);
    setImage("");

    const data = await upload(file, user.username, "profile-image");
    const uploadedFileUrl = data?.location;

    await dispatch(
      updateUser({
        userId: parseFloat(user.id.toString()),
        firstName: user.firstName,
        lastName: user.lastName,
        profileImageUrl: uploadedFileUrl,
        bannerImageUrl: user.bannerImageUrl,
        headline: user.headline,
        phone1: user.phone1,
        phone2: user.phone2,
        address: user.address,
        email: user.email,
      })
    );
    setLoading(false);
    setProfileImageUrl(uploadedFileUrl);
  };

  return (
    <>
      {isLoading && <Loader />}
      {image && (
        <ImageCropper
          image={image}
          onCloseClick={() => {
            setImage("");
          }}
          onUploadClick={(url) => onCroppedImageUpload(url)}
        />
      )}
      <div
        className="profile-image-wrapper"
        onClick={() => {
          fileInput.click();
        }}
      >
        {/* <div className="half-circle"></div> */}
        <img
          src={profileImageUrl}
          alt="Profile Image"
          className="profile-image"
        />
        {canEdit && (
          <>
            <div className="camera-wrapper">
              <img src={cameraIcon} alt="Edit" className="camera" />
            </div>
            <input
              type="file"
              accept="image/jpg"
              style={{ display: "none" }}
              ref={(input) => {
                fileInput = input;
              }}
              onChange={(e) => onChange(e)}
              onClick={(
                event: React.MouseEvent<HTMLInputElement, MouseEvent>
              ) => {
                const element = event.target as HTMLInputElement;
                element.value = "";
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ProfileImage;
