import config from "../../config";
import User from "../../features/user/user.type";
import { upload as uploadFile } from "./file-upload.service";

export const getVCard = (user: User) => {
  var start = "BEGIN:VCARD\nVERSION:3.0";
  var end = "END:VCARD";
  var data = "";
  data += "N:" + user.lastName + ";" + user.firstName;
  data += "\n";
  data += "TEL;TYPE=CELL:" + user.phone1;
  data += "\n";
  data += "TEL;TYPE=WORK,VOICE:" + user.phone2;
  data += "\n";
  data += "EMAIL;TYPE=PREF,INTERNET:" + user.email;
  data += "\n";
  data += "URL;CHARSET=UTF-8:" + config.APP_URL + user.username;
  data += "\n";
  data += "ORG;CHARSET=UTF-8:" + user.headline;
  data += "\n";
  return start + "\n" + data + end;
};

const vCard = function () {
  var start = "BEGIN:VCARD\nVERSION:3.0";
  var end = "END:VCARD";
  var data = "";

  var init = function () {
    data = "";
  };

  var name = function (surname, lastname) {
    data += "N:" + lastname + ";" + surname;
    data += "\n";
  };

  var cell = function (cell) {
    data += "TEL;TYPE=CELL:" + cell;
    data += "\n";
  };

  var work = function (work) {
    data += "TEL;TYPE=WORK,VOICE:" + work;
    data += "\n";
  };

  var other = function (work) {
    data += "TEL;TYPE=OTHER:" + work;
    data += "\n";
  };

  var email = function (email) {
    data += "EMAIL;TYPE=PREF,INTERNET:" + email;
    data += "\n";
  };

  var get = function () {
    return start + "\n" + data + end;
  };

  return {
    init: init,
    name: name,
    cell: cell,
    work: work,
    other: other,
    email: email,
    get: get,
  };
};

export const upload = async (user: User) => {
  const data = getVCard(user);
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "text/x-vcard;" })
  );
  let blob = await fetch(url).then((r) => r.blob());
  const file = new File([blob], `${user.username}.vcf`);
  await uploadFile(file, user.username, "contact");
};
