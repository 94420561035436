import Action from "../../core/model/action";
import Error from "../../core/model/Error";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const CLEAR_FORGOT_PASSWORD_RESPONSE = "CLEAR_FORGOT_PASSWORD_RESPONSE";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";
export const CLEAR_SET_PASSWORD_RESPONSE = "CLEAR_SET_PASSWORD_RESPONSE";

export const clearForgotPasswordResponse = (): Action<Error> => {
  return {
    type: CLEAR_FORGOT_PASSWORD_RESPONSE,
  };
};

export const forgotPasswordRequest = (): Action<any> => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
};

export const forgotPasswordSuccess = (): Action<any> => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
};

export const forgotPasswordFailure = (error: Error): Action<Error> => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error,
  };
};

export const setPasswordRequest = (): Action<any> => {
  return {
    type: SET_PASSWORD_REQUEST,
  };
};

export const setPasswordSuccess = (): Action<any> => {
  return {
    type: SET_PASSWORD_SUCCESS,
  };
};

export const setPasswordFailure = (error: Error): Action<Error> => {
  return {
    type: SET_PASSWORD_FAILURE,
    payload: error,
  };
};

export const clearSetPasswordResponse = (): Action<Error> => {
  return {
    type: CLEAR_SET_PASSWORD_RESPONSE,
  };
};
