import React from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

interface LayoutProps {
  browserView: () => void;
  mobileView: () => void;
}

const Layout = (props: LayoutProps) => {
  const { browserView, mobileView } = props;
  return (
    <>
      {isBrowser && browserView()}
      {isMobile && mobileView()}
    </>
  );
};

export default Layout;
