import Reaxct from "react";
import "./textbox.scss";

export interface TextboxProps {
  label?: string;
  name: string;
  value: string;
  maxLength?: number;
  placeholderText?: string;
  onChange: (e) => void;
  disabled?: boolean;
  prefix?: string;
  customClass?: string;
}

const Textbox = (props: TextboxProps) => {
  const {
    label,
    name,
    value,
    maxLength,
    placeholderText,
    onChange,
    disabled,
    prefix,
    customClass,
  } = props;

  const handleOnChange = (e) => {
    onChange(e);
  };

  const getClassName = () => {
    let className = "";
    if (prefix) className += "with-prefix ";
    if (customClass) className += customClass;
    return className;
  };

  return (
    <div className={`textbox-wrapper `}>
      <label>{label}</label>
      {prefix && <i>{prefix}</i>}
      <input
        type="text"
        name={name}
        value={value}
        placeholder={placeholderText}
        maxLength={maxLength}
        autoComplete="off"
        onChange={(e) => handleOnChange(e)}
        disabled={disabled && disabled}
        className={getClassName()}
      />
    </div>
  );
};

export default Textbox;
