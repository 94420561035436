import Action from "../../core/model/action";
import Error from "../../core/model/Error";

export const CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const CLEAR_EMAIL_RESPONSE = "CLEAR_EMAIL_RESPONSE";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const CLEAR_REGISTER_USER__RESPONSE = "CLEAR_REGISTER_USER__RESPONSE";

export const VALIDATE_EMAIL_REQUEST = "VALIDATE_EMAIL_REQUEST";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE";

export const CLEAR_VALIDATION_RESPONSE = "CLEAR_VALIDATION_RESPONSE";

export const checkEmailRequest = (): Action<any> => {
  return {
    type: CHECK_EMAIL_REQUEST,
  };
};

export const clearEmailResponse = (): Action<any> => {
  return {
    type: CLEAR_EMAIL_RESPONSE,
  };
};

export const checkEmailSuccess = (email: string): Action<string> => {
  return {
    type: CHECK_EMAIL_SUCCESS,
    payload: email,
  };
};

export const checkEmailFailure = (error: Error): Action<Error> => {
  return {
    type: CHECK_EMAIL_FAILURE,
    payload: error,
  };
};

export const clearRegisterUserResponse = (): Action<any> => {
  return {
    type: CLEAR_REGISTER_USER__RESPONSE,
  };
};

export const registerUserRequest = (): Action<any> => {
  return {
    type: REGISTER_USER_REQUEST,
  };
};

export const registerUserSuccess = (): Action<string> => {
  return {
    type: REGISTER_USER_SUCCESS,
  };
};

export const registerUserFailure = (error: Error): Action<Error> => {
  return {
    type: REGISTER_USER_FAILURE,
    payload: error,
  };
};

export const clearValidationResponse = (): Action<any> => {
  return {
    type: CLEAR_VALIDATION_RESPONSE,
  };
};

export const validateEmailRequest = (): Action<any> => {
  return {
    type: VALIDATE_EMAIL_REQUEST,
  };
};

export const validateEmailSuccess = (): Action<string> => {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
  };
};

export const validateEmailFailure = (error: Error): Action<Error> => {
  return {
    type: VALIDATE_EMAIL_FAILURE,
    payload: error,
  };
};
