import { Storage } from "aws-amplify";

import S3FileUpload from "react-s3";

// export const upload = async (e): Promise<Object | String> => {
//   const file = e.target.files[0];

//   // Upload the file to s3 with private access level.
//   await Storage.put("profile-pic.jpg", file, {
//     level: "protected",
//     contentType: "image/jpg",
//   });
//   // Retrieve the uploaded file to display
//   const url = await Storage.get("picture.jpg", { level: "protected" });
//   return url;
// };

export const upload = async (file, username: string, type: string) => {
  const config = {
    bucketName: "neotaps-user-uploads",
    dirName: `${username}/${type}`,
    region: "eu-central-1",
    accessKeyId: "AKIA4D2WGTFBLC5BTI4W",
    secretAccessKey: "Qm6/zlXShp2j3cdMAyTtvcwfl2jC/FcKQaIWCK2N",
  };

  /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */
  //const file = e.target.files[0];
  return await S3FileUpload.uploadFile(file, config);
};
