import React, { useEffect } from "react";
import ImageButton, {
  ImageButtonVariant,
} from "../../../components/image-button";
import { SocialMediaType, socialMediaTypes } from "../home.type";
import "./add-link.scss";

interface AddLinkProps {
  onCloseClick(): void;
  onItemClick(socialMediaType: string): string;
}

const AddLink = (props: AddLinkProps) => {
  const { onCloseClick, onItemClick } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="add-link-wrapper">
      <h2>Add links</h2>
      <div className="social-media-items-block">
        {socialMediaTypes.map((item: SocialMediaType, index) => {
          return (
            <div
              key={index}
              className="social-media-item"
              onClick={() => onItemClick(item.type)}
            >
              <img
                src={
                  require(`../../../images/social-media-icons/${item.type}.svg`)
                    .default
                }
                alt={item.type}
              />
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
      <div className="close-block">
        <ImageButton
          variant={ImageButtonVariant.CLOSE}
          onClick={() => onCloseClick()}
        />
      </div>
    </div>
  );
};

export default AddLink;
