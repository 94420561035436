import "./header.scss";
import logo from "../images/logo-white.svg";
import menu from "../images/icons/menu.svg";
import { Mode } from "./banner-with-user-info";

interface HeaderProps {
  mode: Mode;
  onSettingsClick: () => void;
}

const Header = (props: HeaderProps) => {
  const { mode, onSettingsClick } = props;
  return (
    <div className="header-wrapper">
      <div className="logo-block">{/* <img src={logo} alt="logo" /> */}</div>
      <div className="menu-block">
        {mode === Mode.HOME && (
          <img src={menu} alt="menu" onClick={onSettingsClick} />
        )}
      </div>
    </div>
  );
};

export default Header;
