import { ADD_USER } from "./user.action";
import User from "./user.type";

const initialState: User = {
  id: 0,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  headline: "",
  phone1: "",
  phone2: "",
  address: "",
  profileImageUrl: "",
  bannerImageUrl: "",
  isPublicProfile: false,
  profilePassword: "",
  links: undefined,
  files: undefined,
};

export const userReducer = (state = initialState, action: any): User => {
  switch (action.type) {
    case ADD_USER:
      let user = action.payload;
      return {
        ...state,
        ...user,
      };

    default:
      return state;
  }
};
