import React from "react";
import "./button.scss";

interface ButtonProps {
  variant: ButtonVariant;
  disabled?: boolean;
  text: string;
  customClass?: string;
  onClick: (e) => void;
}

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

const Button = (props: ButtonProps) => {
  const { variant, disabled, text, customClass, onClick } = props;

  return (
    <input
      type="button"
      className={`${variant} ${customClass && customClass}`}
      value={text}
      onClick={(e) => onClick(e)}
      disabled={disabled ? disabled : false}
    />
  );
};

export default Button;
