import "./banner.scss";
import defaultBanner from "../images/default-banner.jpg";
import editIcon from "../images/edit.svg";
import Header from "./header";
import { Mode } from "./banner-with-user-info";
import User from "../features/user/user.type";
import React, { useState } from "react";
import Loader from "./loader";
import { updateUser } from "../features/user/user.service";
import { upload } from "../core/services/file-upload.service";
import { useDispatch } from "react-redux";
import ImageCropper from "./image-cropper";
import Button, { ButtonVariant } from "./button";
import { logOut } from "../features/user/user.action";
import Settings from "./settings";

interface BannerProps {
  user: User;
  mode: Mode;
}

const Banner = (props: BannerProps) => {
  const { user, mode } = props;
  const [isLoading, setLoading] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState(
    user.bannerImageUrl ? user.bannerImageUrl : defaultBanner
  );
  const dispatch = useDispatch();
  let fileInput;

  const [image, setImage] = useState(ArrayBuffer[0]);
  const [showSettings, setSettings] = useState(false);

  const onChange = async (e) => {
    const uploadedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
  };

  const onCroppedImageUpload = async (croppedImageUrl) => {
    setLoading(true);
    let fileName = `banner-image-${new Date().toISOString()}.jpeg`;

    const blob = await fetch(croppedImageUrl).then((r) => r.blob());
    const file = new File([blob], fileName);
    setImage(ArrayBuffer[0]);

    const data = await upload(file, user.username, "banner-image");

    const uploadedFileUrl = data?.location;
    setBannerImageUrl(uploadedFileUrl);
    await dispatch(
      updateUser({
        userId: parseFloat(user.id.toString()),
        firstName: user.firstName,
        lastName: user.lastName,
        profileImageUrl: user.profileImageUrl,
        bannerImageUrl: uploadedFileUrl,
        headline: user.headline,
        phone1: user.phone1,
        phone2: user.phone2,
        address: user.address,
        email: user.email,
      })
    );
    setLoading(false);
  };

  const onLogOutClick = () => {
    dispatch(logOut());
  };

  return (
    <>
      {isLoading && <Loader />}
      {image && (
        <ImageCropper
          image={image}
          onCloseClick={() => {
            setImage(ArrayBuffer[0]);
          }}
          onUploadClick={(url) => onCroppedImageUpload(url)}
          minCropBoxHeight={180}
          minCropBoxWidth={360}
        />
      )}
      {showSettings && <Settings onBackClick={() => setSettings(false)} />}
      <div className="banner-wrapper">
        <img src={bannerImageUrl} className="banner" />
        <Header mode={mode} onSettingsClick={() => setSettings(true)} />
        {mode !== Mode.PREVIEW && (
          <>
            <div
              className={`edit-banner-block ${mode}`}
              onClick={() => {
                fileInput.click();
              }}
            >
              <label>Edit banner</label>
            </div>
          </>
        )}
        <input
          type="file"
          accept="image/jpg"
          style={{ display: "none" }}
          ref={(input) => {
            fileInput = input;
          }}
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const element = event.target as HTMLInputElement;
            element.value = "";
          }}
          onChange={(e) => onChange(e)}
        />
      </div>
    </>
  );
};

export default Banner;
