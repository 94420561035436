import {
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  CLEAR_EMAIL_RESPONSE,
  VALIDATE_EMAIL_REQUEST,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  CLEAR_REGISTER_USER__RESPONSE,
  CLEAR_VALIDATION_RESPONSE,
} from "./registration.action";

export interface IRegistration {
  addEmail: IAddEmail;
  addUserLink: IAddUserLink;
  validation: IValidation;
}

interface IAddEmail {
  isLoading: boolean;
  email: string;
  error: string;
  isSuccess: boolean | undefined;
}

interface IAddUserLink {
  isLoading: boolean;
  email: string;
  userLink: string;
  password: string;
  error: string;
  isSuccess: boolean | undefined;
}

interface IValidation {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
}

const initialState: IRegistration = {
  addEmail: { isLoading: false, email: "", error: "", isSuccess: undefined },
  addUserLink: {
    isLoading: false,
    email: "",
    userLink: "",
    password: "",
    error: "",
    isSuccess: undefined,
  },
  validation: {
    isLoading: false,
    error: "",
    isSuccess: undefined,
  },
};

export const registrationReducer = (
  state = initialState,
  action: any
): IRegistration => {
  switch (action.type) {
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        addEmail: { ...state.addEmail, isLoading: true },
      };
    case CLEAR_EMAIL_RESPONSE:
      return {
        ...state,
        addEmail: {
          ...state.addEmail,
          isLoading: false,
          email: "",
          error: "",
          isSuccess: undefined,
        },
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        addEmail: {
          ...state.addEmail,
          isLoading: false,
          isSuccess: true,
          email: action.payload,
        },
      };
    case CHECK_EMAIL_FAILURE:
      return {
        ...state,
        addEmail: {
          ...state.addEmail,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        addUserLink: {
          ...state.addUserLink,
          isLoading: true,
        },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        addUserLink: {
          ...state.addUserLink,
          isLoading: false,
          isSuccess: true,
        },
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        addUserLink: {
          ...state.addUserLink,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    case CLEAR_REGISTER_USER__RESPONSE:
      return {
        ...state,
        addUserLink: {
          ...state.addUserLink,
          isLoading: false,
          error: "",
          isSuccess: undefined,
        },
      };
    case VALIDATE_EMAIL_REQUEST:
      return {
        ...state,
        validation: {
          ...state.validation,
          isLoading: true,
          isSuccess: undefined,
          error: "",
        },
      };
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        validation: {
          ...state.validation,
          isLoading: false,
          isSuccess: true,
        },
      };
    case VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        validation: {
          ...state.validation,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    case CLEAR_VALIDATION_RESPONSE:
      return {
        ...state,
        validation: {
          ...state.validation,
          isLoading: false,
          error: "",
          isSuccess: undefined,
        },
      };
    default:
      return state;
  }
};
