import {
  HOME_SHOW_LOADER,
  CREATE_EDIT_LINK_SUCCESS,
  CREATE_EDIT_LINK_FAILURE,
  CREATE_EDIT_LINK_CLEAR,
  CREATE_EDIT_FILE_SUCCESS,
  CREATE_EDIT_FILE_FAILURE,
  CREATE_EDIT_FILE_CLEAR,
} from "./home.action";

export interface Home {
  isLoading: boolean;
  createEditLink: CreateEditLink;
  createEditFile: CreateEditFile;
}

interface CreateEditLink {
  error: string;
  isSuccess: boolean;
}

interface CreateEditFile {
  error: string;
  isSuccess: boolean;
}

const initialState: Home = {
  isLoading: false,
  createEditLink: { error: "", isSuccess: false },
  createEditFile: { error: "", isSuccess: false },
};

export const homeReducer = (state = initialState, action: any): Home => {
  switch (action.type) {
    case HOME_SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_EDIT_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createEditLink: {
          ...state.createEditLink,
          isSuccess: true,
          error: "",
        },
      };
    case CREATE_EDIT_LINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        createEditLink: {
          ...state.createEditLink,
          isSuccess: false,
          error: action.payload,
        },
      };
    case CREATE_EDIT_LINK_CLEAR:
      return {
        ...state,
        isLoading: false,
        createEditLink: {
          isSuccess: false,
          error: "",
        },
      };
    case CREATE_EDIT_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createEditFile: {
          ...state.createEditFile,
          isSuccess: true,
          error: "",
        },
      };
    case CREATE_EDIT_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        createEditFile: {
          ...state.createEditFile,
          isSuccess: false,
          error: action.payload,
        },
      };
    case CREATE_EDIT_FILE_CLEAR:
      return {
        ...state,
        isLoading: false,
        createEditFile: {
          isSuccess: false,
          error: "",
        },
      };
    default:
      return state;
  }
};
