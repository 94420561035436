import React, { useState } from "react";
import BannerWithUserInfo, {
  Mode,
} from "../../components/banner-with-user-info";
import SocialMediaBox from "./social-media-box";
import User, { Link, File } from "../user/user.type";
import "./mobile-view.scss";
import FileBox from "./file-box";
import backIcon from "../../images/icons/back.svg";

interface MobileViewProps {
  user: User;
  isExternalUser: boolean;
  onBackClick: () => void;
}

const MobileView = (props: MobileViewProps) => {
  const { user, isExternalUser, onBackClick } = props;
  enum Menu {
    LINKS = "links",
    FILES = "files",
  }

  const [menu, setMenu] = useState(Menu.LINKS);
  const renderLinks = (links: Link[] | undefined) => {
    if (links) {
      links = links
        .filter((n) => n.showOnProfile === true)
        .sort((a, b) => Number(a.id) - Number(b.id));
      return (
        <div className="links-block-preview">
          {links.map((item, index) => {
            return <SocialMediaBox key={index} item={item} />;
          })}
        </div>
      );
    }
  };

  const renderFiles = (files: File[] | undefined) => {
    if (files) {
      files = files
        .filter((n) => n.showOnProfile === true)
        .sort((a, b) => Number(a.id) - Number(b.id));
      return (
        <div className="files-block-preview">
          {files.map((item, index) => {
            return <FileBox key={index} item={item} />;
          })}
        </div>
      );
    }
  };
  return (
    <div className="preview-wrapper-mobile">
      <BannerWithUserInfo user={user} mode={Mode.PREVIEW} />
      <div className="preview-content">
        <div className="header-links-block">
          <a
            href="#!"
            className={menu === Menu.LINKS ? "selected" : ""}
            onClick={() => setMenu(Menu.LINKS)}
          >
            My Links
          </a>
          <a
            href="#!"
            className={menu === Menu.FILES ? "selected" : ""}
            onClick={() => setMenu(Menu.FILES)}
          >
            My Files
          </a>
        </div>
        <div>
          {menu === Menu.LINKS && renderLinks(user.links)}
          {menu === Menu.FILES && renderFiles(user.files)}
        </div>
      </div>
      {!isExternalUser && (
        <div className="back-to-profile-wrapper">
          <img src={backIcon} alt="Back" />
          <a href="#!" onClick={(e) => onBackClick()}>
            Back to profile
          </a>
        </div>
      )}
    </div>
  );
};

export default MobileView;
