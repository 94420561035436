import React, { FunctionComponent, useEffect, useState } from "react";
import Back from "../back";
import "./index.scss";
import { logOut } from "../../features/user/user.action";
import { useDispatch } from "react-redux";
import profileSettingsIcon from "../../images/icons/profile-settings.svg";
import signOutIcon from "../../images/icons/signout.svg";
import addCodeIcon from "../../images/icons/add-code.svg";
import SettingsBox from "./settings-box";
import ProfileSettings from "./profile-settings";
import AddCode from "./add-code";
import { routePaths } from "../../infrastructure/constants";
import { Redirect } from "react-router-dom";

interface SettingsProps {
  onBackClick: () => void;
}

const Settings: FunctionComponent<SettingsProps> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const dispatch = useDispatch();

  const [showProfileSettings, setProfileSettings] = useState(false);
  const [showAddCode, setAddCode] = useState(false);
  const [redirectToStore, setRedirectToStore] = useState(false);

  const { onBackClick } = props;

  const onLogOutClick = () => {
    dispatch(logOut());
  };

  const settings = [
    {
      image: profileSettingsIcon,
      text: "Store",
      onClick: () => setRedirectToStore(true),
    },
    {
      image: profileSettingsIcon,
      text: "Profile settings",
      onClick: () => setProfileSettings(true),
    },
    {
      image: addCodeIcon,
      text: "Add code",
      onClick: () => setAddCode(true),
    },
  ];

  if (showProfileSettings) {
    return (
      <>
        <div className="overlay"></div>
        <ProfileSettings onBackClick={() => setProfileSettings(false)} />
      </>
    );
  }

  if (showAddCode) {
    return (
      <>
        <div className="overlay"></div>
        <AddCode onBackClick={() => setAddCode(false)} />
      </>
    );
  }

  if (redirectToStore) {
    return <Redirect to={routePaths.STORE} />;
  }

  return (
    <>
      <div className="overlay"></div>

      <div className="settings-wrapper">
        <div className="header-block">
          <div className="header-holder">
            <Back onClick={() => onBackClick()} />
            <h2>Settings</h2>
          </div>
        </div>
        <div className="settings-menu-block">
          {settings.map((item, index) => {
            return (
              <SettingsBox
                key={index}
                image={item.image}
                text={item.text}
                onClick={item.onClick}
              />
            );
          })}
        </div>
        <div className="signout-block">
          <a href="#!" onClick={() => onLogOutClick()}>
            <img src={signOutIcon} alt={"sign out"} />
            <span>Sign out</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Settings;
