import Error from "../../core/model/Error";
import {
  addDetailsRequest,
  addDetailsSuccess,
  addDetailsFailure,
  addCodeRequest,
  addCodeSuccess,
  addCodeFailure,
  clearDetailsResponse,
  clearCodeResponse,
} from "./onboarding.action";
import { addUser } from "../user/user.action";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import config from "../../config";
import { AddDetailsInput, AddCodeInput } from "./onboarding.type";
import { upload } from "../../core/services/contact-card.service";

export const addDetails = (addDetailsInput: AddDetailsInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation addDetails($addDetailsInput:AddDetailsInput!){
        addDetails(addDetailsInput:$addDetailsInput){
            isSuccess,
            message,
            user{
                id,
                username,
                firstName,
                lastName,
                email,
                headline,
                phone1,
                phone2,
                profileImageUrl,
                bannerImageUrl,
                isPublicProfile,
                profilePassword,
            }
        }
    }
  `;

  dispatch(addDetailsRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        addDetailsInput: { ...addDetailsInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.addDetails;
      if (data.isSuccess) {
        dispatch(addDetailsSuccess());
        dispatch(addUser(data.user));
        upload(data.user);
      } else {
        dispatch(addDetailsFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(addDetailsFailure(error.message));
    });
};

export const addCode = (addCodeInput: AddCodeInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation addCode($addCodeInput:AddCodeInput!){
        addCode(addCodeInput:$addCodeInput){
            isSuccess,
            message,
        }
    }
  `;

  dispatch(addCodeRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        addCodeInput: { ...addCodeInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.addCode;

      if (data.isSuccess) {
        dispatch(addCodeSuccess());
      } else {
        dispatch(addCodeFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(addCodeFailure(error.message));
    });
};

export const clearAddDetailsResponse = () => async (
  dispatch: Dispatch<Action<any>>
) => {
  dispatch(clearDetailsResponse());
};

export const clearAddCodeResponse = () => async (
  dispatch: Dispatch<Action<any>>
) => {
  dispatch(clearCodeResponse());
};
