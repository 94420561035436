import Error from "../../core/model/Error";
import {
  checkEmailRequest,
  checkEmailSuccess,
  checkEmailFailure,
  registerUserRequest,
  registerUserSuccess,
  registerUserFailure,
  validateEmailRequest,
  validateEmailSuccess,
  validateEmailFailure,
  clearEmailResponse,
  clearRegisterUserResponse,
  clearValidationResponse,
} from "./registration.action";
import { addUser } from "../user/user.action";
import { Dispatch } from "react";
import Action from "../../core/model/action";
import config from "../../config";
import { RegisterUserInput, ValidateEmailInput } from "./registration.type";

export const checkEmail = (email: string) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation isEmailAvailable($isEmailAvailableInput:IsEmailAvailableInput!){
      isEmailAvailable(isEmailAvailableInput:$isEmailAvailableInput){
        isSuccess,
        message
      }
    }
  `;

  dispatch(checkEmailRequest());
  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        isEmailAvailableInput: {
          email: email,
        },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.isEmailAvailable;

      if (data.isSuccess) {
        dispatch(checkEmailSuccess(email));
      } else {
        dispatch(checkEmailFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(checkEmailFailure(error.message));
    });
};

export const registerUser = (registerUserInput: RegisterUserInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation registerUser($registerUserInput: RegisterUserInput!){
      registerUser(registerUserInput:$registerUserInput){
        isSuccess,
        message,
        user{
          id,
          username,
          firstName,
          lastName,
          email,
          headline,
          phone1,
          phone2,
          profileImageUrl,
          bannerImageUrl,
          isValidated
        }
      }
    }
  `;

  dispatch(registerUserRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        registerUserInput: { ...registerUserInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.registerUser;

      if (data.isSuccess) {
        dispatch(registerUserSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(registerUserFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(registerUserFailure(error.message));
    });
};

export const validateEmail = (validateEmailInput: ValidateEmailInput) => async (
  dispatch: Dispatch<Action<string | Error | any>>
) => {
  const mutation = `
    mutation validateEmail($validateEmailInput: ValidateEmailInput!){
      validateEmail(validateEmailInput:$validateEmailInput){
        isSuccess,
        message,
        user{
          id,
          username,
          firstName,
          lastName,
          email,
          headline,
          phone1,
          phone2,
          profileImageUrl,
          bannerImageUrl,
          isValidated
        }
      }
    }
  `;

  dispatch(validateEmailRequest());

  fetch(config.API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: mutation,
      variables: {
        validateEmailInput: { ...validateEmailInput },
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      const data = response.data.validateEmail;

      if (data.isSuccess) {
        dispatch(validateEmailSuccess());
        dispatch(addUser(data.user));
      } else {
        dispatch(validateEmailFailure(data.message));
      }
    })
    .catch((error) => {
      dispatch(validateEmailFailure(error.message));
    });
};

export const clearAddEmailResponse = () => async (
  dispatch: Dispatch<Action<any>>
) => {
  dispatch(clearEmailResponse());
};

export const clearAddDetailsResponse = () => async (
  dispatch: Dispatch<Action<any>>
) => {
  dispatch(clearRegisterUserResponse());
};

export const clearEmailValidationResponse = () => async (
  dispatch: Dispatch<Action<any>>
) => {
  dispatch(clearValidationResponse());
};
