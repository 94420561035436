import React, { FunctionComponent } from "react";
import "./external-page.layout.scss";
import logo from "../images/logo-white.svg";
import Loader from "../components/loader";

interface ExternalPageLayoutProps {
  isLoading: boolean;
}

const ExternalPageLayout: FunctionComponent<ExternalPageLayoutProps> = (
  props
) => {
  if (props.isLoading) {
    return <Loader />;
  }
  return (
    <div className="external-page-layout">
      <div className="left-block">
        <img src={logo} alt="neotaps" className="logo" />
        {/* <h2 className="text">Welcome to Neotaps</h2> */}
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default ExternalPageLayout;
