import Error from "../../core/model/Error";
import Action from "../../core/model/action";
import IUser from "../user/user.type";

export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";
export const CLEAR_LOGIN_RESPONSE = "CLEAR_LOGIN_RESPONSE";

export const clearLoginResponse = (): Action<any> => {
  return {
    type: CLEAR_LOGIN_RESPONSE,
  };
};

export const fetchLoginRequest = (): Action<any> => {
  return {
    type: FETCH_LOGIN_REQUEST,
  };
};

export const fetchLoginSuccess = (): Action<any> => {
  return {
    type: FETCH_LOGIN_SUCCESS,
  };
};

export const fetchLoginFailure = (error: Error): Action<Error> => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: error,
  };
};
