import React from "react";
import "./add-button.scss";
import addIcon from "../../images/icons/add-white.svg";

interface AddButtonProps {
  onClick(): void;
}

const AddButton = (props: AddButtonProps) => {
  const { onClick } = props;

  return (
    <div className="add-button-wrapper" onClick={() => onClick()}>
      <img src={addIcon} alt="Add" />
    </div>
  );
};

export default AddButton;
