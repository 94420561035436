import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../back";
import Button, { ButtonVariant } from "../button";
import ErrorMessage from "../error-message";
import PasswordBox from "../passoword-box";
import ToggleSwitch from "../toggle-switch";
import "./profile-settings.scss";
import { DefaultRootState } from "../../core/reducer/root.reducer";
import User from "../../features/user/user.type";
import {
  addDetails,
  clearAddDetailsResponse,
} from "../../features/onboarding/onboarding.service";
import { IOnboarding } from "../../features/onboarding/onboarding.reducer";
import SuccessMessage from "../success-message";
import Loader from "../loader";

interface ProfileSettingsProps {
  onBackClick: () => void;
}
const ProfileSettings = (props: ProfileSettingsProps) => {
  const { onBackClick } = props;
  const dispatch = useDispatch();
  const state = useSelector<DefaultRootState, IOnboarding>(
    (state) => state.onboarding
  );
  const user = useSelector<DefaultRootState, User>((state) => state.user);
  const [password, setPassword] = useState(
    user.profilePassword ? user.profilePassword : ""
  );

  const [publicProfile, setPublicProfile] = useState(user.isPublicProfile);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      dispatch(clearAddDetailsResponse());
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (state.addDetails.isSuccess) {
        setLoading(false);
        setSuccessMessage("Profile settings updated successfully!");
      } else {
        setLoading(false);
        setErrorMessage(state.addDetails.error);
      }
      // do componentDidUpdate logic
    }
  });

  const onSaveClick = () => {
    setErrorMessage("");
    setLoading(true);
    dispatch(
      addDetails({
        userId: parseFloat(user.id.toString()),
        profileImageUrl: user.profileImageUrl ? user.profileImageUrl : "",
        firstName: user.firstName,
        lastName: user.lastName,
        isPublicProfile: publicProfile,
        profilePassword: password,
      })
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="profile-settings-wrapper">
        <div className="header-block">
          <div className="header-holder">
            <Back onClick={() => onBackClick()} />
            <h2>Profile Settings</h2>
          </div>
        </div>
        <div className="profile-settings-content-block">
          <div className="public-profile-wrapper">
            <label>Make your profile public</label>
            <ToggleSwitch
              id="publicProfile"
              checked={publicProfile}
              onChange={(checked) => setPublicProfile(checked)}
            />
          </div>
          {!publicProfile && (
            <PasswordBox
              name="password"
              label="Set Profile key"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          )}
          <div
            className={`action-block ${publicProfile ? "public" : "private"}`}
          >
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {successMessage && <SuccessMessage message={successMessage} />}
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Save"
              onClick={(e) => onSaveClick()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSettings;
