import React from "react";
import { routePaths } from "./constants";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { DefaultRootState } from "../core/reducer/root.reducer";
import IUser from "../features/user/user.type";

const PrivateRoute = (props) => {
  const user = useSelector<DefaultRootState, IUser>((state) => state.user);
  const { path, component } = props;

  if (user && user.id > 0)
    return <Route exact path={path} component={component} />;

  return <Redirect to={routePaths.LOGIN} />;
};

export default PrivateRoute;
