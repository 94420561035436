import Error from "../../core/model/Error";
import Action from "../../core/model/action";
import IUser from "./user.type";

export const ADD_USER = "ADD_USER";
export const USER_LOGOUT = "USER_LOGOUT";

export const addUser = (user: IUser): Action<IUser> => {
  return {
    type: ADD_USER,
    payload: user,
  };
};

export const logOut = (): Action<any> => {
  return {
    type: USER_LOGOUT,
  };
};
