import {
  ADD_DETAILS_REQUEST,
  ADD_DETAILS_SUCCESS,
  ADD_DETAILS_FAILURE,
  ADD_CODE_REQUEST,
  ADD_CODE_SUCCESS,
  ADD_CODE_FAILURE,
  CLEAR_ADD_DETAILS_RESPONSE,
  CLEAR_ADD_CODE_RESPONSE,
} from "./onboarding.action";

export interface IOnboarding {
  addDetails: AddDetails;
  addCode: AddCode;
}

interface AddDetails {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
}

interface AddCode {
  isLoading: boolean;
  error: string;
  isSuccess: boolean | undefined;
}

const initialState: IOnboarding = {
  addDetails: { isLoading: false, error: "", isSuccess: undefined },
  addCode: { isLoading: false, error: "", isSuccess: undefined },
};

export const onboardingReducer = (
  state = initialState,
  action: any
): IOnboarding => {
  switch (action.type) {
    case CLEAR_ADD_DETAILS_RESPONSE:
      return {
        ...state,
        addDetails: { ...state.addDetails, error: "", isSuccess: undefined },
      };
    case ADD_DETAILS_REQUEST:
      return {
        ...state,
        addDetails: { ...state.addDetails, isLoading: true },
      };
    case ADD_DETAILS_SUCCESS:
      return {
        ...state,
        addDetails: {
          ...state.addDetails,
          isLoading: false,
          isSuccess: true,
        },
      };
    case ADD_DETAILS_FAILURE:
      return {
        ...state,
        addDetails: {
          ...state.addDetails,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    case CLEAR_ADD_CODE_RESPONSE:
      return {
        ...state,
        addCode: { ...state.addCode, error: "", isSuccess: undefined },
      };
    case ADD_CODE_REQUEST:
      return {
        ...state,
        addCode: {
          ...state.addCode,
          isLoading: true,
        },
      };
    case ADD_CODE_SUCCESS:
      return {
        ...state,
        addCode: {
          ...state.addCode,
          isLoading: false,
          isSuccess: true,
        },
      };
    case ADD_CODE_FAILURE:
      return {
        ...state,
        addCode: {
          ...state.addCode,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
